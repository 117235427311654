import * as React from "react";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import { updateVolcanoDb } from "./Volcano";
import { CurrentUser, CurrentUserContext, fromRawCurrentUser} from "./utils/permissions";
import RouteHandler from "./utils/routeHandler";


function App() {
const [currentUser, setCurrentUser] = React.useState<CurrentUser>({ "role": "public" })
  useEffect(() => {
    updateVolcanoDb();
  }, []);

  useEffect(() => {
    fetch("/api/users/current/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            `Failed to get current user: ${res.status} ${res.statusText}`
          );
        }
      })
      .then((json: unknown) => {
        setCurrentUser(fromRawCurrentUser(json));
      })
      .catch((e: unknown) => {
        console.error(e);
      });
  }, []);
  return (
    <CurrentUserContext.Provider value={currentUser}>
      <Navbar></Navbar>
      <RouteHandler currentUser={currentUser} />
    </CurrentUserContext.Provider>
  );
}

export default App;