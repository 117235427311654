import { createContext, useContext } from "react";

export type Role =
  | "suspended"
  | "public"
  | "citizen"
  | "volcanologist"
  | "moderator"
  | "admin";

export type CurrentUser =
  | { role: "public" }
  | {
      role: Exclude<Role, "public">;
      username: string;
      email: string;
      id: string;
      phone: string;
      metric: boolean;
    };

type RawCurrentUser = {
  username: string;
  email: string;
  user_id: string;
  phone: string;
  role: Role;
  use_metric: boolean;
};

export function fromRawCurrentUser(untyped: unknown): CurrentUser {
  const raw = untyped as RawCurrentUser;
  return {
    role: raw.role,
    username: raw.username,
    email: raw.email,
    id: raw.user_id,
    phone: raw.phone,
    metric: raw.use_metric,
  }
}

export const CurrentUserContext = createContext<CurrentUser>({
  role: "public",
});

const permissionLevels: Record<Role, number> = {
  suspended: -1,
  public: 0,
  citizen: 1,
  volcanologist: 2,
  moderator: 3,
  admin: 4,
};

export function Restricted<C>({
  role,
  children,
}: {
  role: Role;
  children: C;
}): C | null {
  const currentUser = useContext(CurrentUserContext);
  if (permissionLevels[currentUser.role] >= permissionLevels[role]) {
    return children;
  } else {
    return null;
  }
}

export type set = {
  setName: string,
  roles: Role[]
};

export function roleSets() : Array<set> {
  return [
    {
      setName: "all_but_suspended",
      roles: ["admin", "citizen", "moderator", "public", "volcanologist"]
    },
    {
      setName: "all_but_suspended_public",
      roles: ["admin", "citizen", "moderator", "volcanologist"]
    },
    {
      setName: "admin_volcanologist",
      roles: ["admin", "volcanologist"]
    },
    {
      setName: "admin_mod",
      roles: ["admin", "moderator"]
    }
    
  ];

}