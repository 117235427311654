import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import * as React from "react";

export function BackButtonHeader({ title, onBack }: { title: string; onBack: () => void }) {
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "48px 1fr 48px",
        textAlign: "center",
      }}
    >
      <IconButton onClick={onBack} style={{ alignSelf: "center" }}>
        <ArrowBack style={{ width: "32px", height: "32px" }} />
      </IconButton>
      <h2 style={{ margin: "20px 0" }}>
        {title}
      </h2>
    </div>
  );
}