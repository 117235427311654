import * as React from 'react';
import { Box, Select, SelectChangeEvent } from '@mui/material'
import { Button, Menu, MenuItem, Tooltip, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DeleteAccount from './DeleteAccount';
import { Restricted } from "../utils/permissions";
import { Link } from './Link';



export function UserMenuForReportedUser({
    username,
    userid,
    postid
    } : {
    username:String;
    userid:Number;
    postid?: string;
    }) {      


        let location = useLocation();
        let navigate = useNavigate();

        const page = location.pathname.split('/')
        useEffect(() => {
            console.log(location.pathname);
          }, [location]);
        

        const [anchor, setAnchor] = useState<null | HTMLElement>(null)

        const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchor(event.currentTarget);
        };

        const closeMenu = () => {
            setAnchor(null);
        };


        /*
        *  Suspend Account Modal
        */
        const [openSuspend, setOpenSuspend] = React.useState(false);
        const handleOpenSuspend = () => {
            getCurrentRole();
            setOpenSuspend(true);
        }
        const handleCloseSuspend = () => setOpenSuspend(false);

        let handleSuspendSave = () => {
            setNewRole('suspended');

            handleSaveNewRole();
        }


        /*
        * Change Role Modal
        */
        const [openRole, setOpenRole] = React.useState(false);
        const handleOpenRole = () => {
            getCurrentRole();
            setOpenRole(true);
        }

        const handleCloseRole = () => setOpenRole(false);
    
        const [newRole, setNewRole] = useState('');
        const handleNewRole = (event: SelectChangeEvent) => {
            setNewRole(event.target.value);
        };
        const [currentRole, setCurrentRole] = useState('');

        let getCurrentRole = async () => {
            fetch(`/api/users/role/`, {
                method: 'POST',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    targetID: userid,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                setCurrentRole(data.targetRole)
            })
            .catch((error) => {
                console.error("Error:", error)
            })
        }

        let handleSaveNewRole = async () => {
            fetch(`/api/users/role/`, {

              method: 'PATCH',
              headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-type': 'application/json'
              },
              body: JSON.stringify({
                targetID: userid,
                newRole: newRole,
              })
            })
            .then((response) => {
                setCurrentRole(newRole)
                return response
            })
            .catch((error) => {
                console.error("Error:", error)
            })
            handleClose();
        }


        /*
        * Send Message from Volcanologist Modal
        */
        const fromContact = localStorage.getItem('userID')
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        let sendVolcanologistInquiry = async () => {
            fetch(`/api/users/volcanologistContact/`, {

              method: 'POST',
              headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-type': 'application/json'
              },
              body: JSON.stringify({
                toContact: userid,
                fromContact: fromContact,
                postid: postid,
              })
            })
            .then((response) => {
              if(response.ok){
                return response
              }
              else{
                throw response
              }
            })
            handleClose();
          }

        /*
        * Delete Report on the user
        */
        const [openReport, setOpenReport] = React.useState(false);
        const handleOpenReport = () => {
            getCurrentRole();
            setOpenReport(true);
        }
        const handleCloseReport = () => setOpenReport(false);
        async function deleteUserReport() {
            fetch(`/api/reports/deleteUserReport/${userid}/`, {
                method: 'GET',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`
                }
            })
            .then((res) => {
                if(res.ok) {
                    fetch('/api/reports/getAllReportedUser/', {
                        headers: {
                            method: "GET",
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        }
                    })
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        console.log(data);
                        window.location.replace('/viewreportuser/');
                       
                    })
                    
                }
            })     
        }

          const modalStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
        };
        console.log(postid)
        switch (page[1]) {
            case 'myprofile': {
                return(
                    <Box style = {{alignItems: 'center'}}>
                        <Button onClick={openMenu}
                            variant="outlined"
                            sx={{ m: 3, width: 250}}
                        >
                            More Actions
                        </Button>
                        <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={closeMenu}
                            keepMounted
                            PaperProps={{
                            style: {
                                maxHeight: 40 * 6,
                                width: 250
                            },
                            }}
                        >
                            <MenuItem
                                onClick = {() => {navigate("/myaccount")}}
                            > Edit Account
                            </MenuItem>

                            <MenuItem
                                onClick = {() => {navigate("/password-change")}}
                            > Change Password
                            </MenuItem>

                            <MenuItem
                                onClick = {() => {navigate("/logout")}}
                            > Log Out
                            </MenuItem>

                           
                            <DeleteAccount userid = {userid}/>
                           

                        </Menu>
                    </Box>
                )
            }

            default: {
                return(
                    <Box>
                        <Button onClick={openMenu} 
                            variant="outlined"
                            sx={{ m: 3, width:250}}
                        >
                            More Actions
                        </Button>
                        <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={closeMenu}
                            keepMounted
                            PaperProps={{
                            style: {
                                maxHeight: 40 * 6,
                                width: 250
                            },
                            }}
                        >
                            <Tooltip title='Report this user to our moderation team.' placement='right' enterDelay={250}>
                                <MenuItem>
                                    <Link to={`/reportUser/${userid}`}>
                                        Report User
                                    </Link>
                                </MenuItem>
                            </Tooltip>
                            
                            <Restricted role="volcanologist">
                            <Tooltip title='Send default message to this users preferred contact.' placement='right' enterDelay={250}>
                                <MenuItem onClick={handleOpen}>
                                    Contact User
                                </MenuItem>
                            </Tooltip>
                            </Restricted>

                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={modalStyle}>
                                            <Typography id="modal-modal-title" 
                                                variant="h6" component="h2">
                                                Send default message to the user.
                                            </Typography>
                                            <Typography id="modal-modal-description" 
                                                sx={{ mt: 2 }}>
                                                This will send an email or text message to the user. It will include your contact information for them to follow up with you. Are you sure you want to send this message?
                                            </Typography>
                                            <Box>
                                                <Button onClick={handleClose}>No</Button> <Button onClick={sendVolcanologistInquiry}>Yes</Button>
                                            </Box>
                                        </Box>
                                    </Modal>

                            <Restricted role="moderator">
                            <Tooltip title='(Moderator Only) Suspend this user.' placement='right' enterDelay={250}>
                                <MenuItem onClick={handleOpenSuspend}>
                                    Suspend Account
                                </MenuItem>
                            </Tooltip>
                            </Restricted>
                                    <Modal
                                        open={openSuspend}
                                        onClose={handleCloseSuspend}
                                        aria-labelledby="modal-suspend-title"
                                        aria-describedby="modal-suspend-description"
                                    >
                                        <Box sx={modalStyle}>
                                            <Typography id="modal-suspend-title" 
                                                variant="h6" component="h2">
                                                Suspend Account
                                            </Typography>
                                            <Typography id="modal-suspend-description" 
                                                sx={{ mt: 2 }}>
                                               Suspend this account so they can no longer log into Did You See It.
                                            </Typography>
                                            <p>Current User: {username}</p>
                                            <p>Current Role: {currentRole}</p>

                                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                                    <Select
                                                        labelId='user-role-select-label'
                                                        id='user-role-select'
                                                        value={newRole}
                                                        label="Role"
                                                        onChange={handleNewRole}
                                                    >
                                                        <MenuItem value={currentRole}>No</MenuItem>
                                                        <MenuItem value={'suspended'}>Yes</MenuItem>

                                                    </Select>
                                            </FormControl>

                                            <Box>
                                                <Button onClick={handleCloseSuspend}>Close</Button> <Button onClick={handleSuspendSave}>Save</Button>
                                            </Box>
                                        </Box>
                                    </Modal>
                                

                            <Restricted role="admin">
                            <Tooltip title="Change this user's role" placement='right' enterDelay={250}>
                                <MenuItem onClick={handleOpenRole}>
                                    Change Role
                                </MenuItem>
                            </Tooltip>
                            </Restricted>
                                    <Modal
                                        open={openRole}
                                        onClose={handleCloseRole}
                                        aria-labelledby="modal-role-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={modalStyle}>
                                            <Typography id="modal-role-title" 
                                                variant="h6" component="h2">
                                                Change Role
                                            </Typography>
                                            <Typography id="modal-modal-description" 
                                                sx={{ mt: 2 }}>
                                                Changing this user's role will change what permissions they have on Did You See It. Make sure you only assign users the lowest role they need.
                                            </Typography>
                                            <p>Current User: {username}</p>
                                            <p>Current Role: {currentRole}</p>

                                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                                <InputLabel>Role</InputLabel>
                                                    <Select
                                                        labelId='user-role-select-label'
                                                        id='user-role-select'
                                                        value={newRole}
                                                        label="Role"
                                                        onChange={handleNewRole}
                                                    >
                                                        <MenuItem value={'citizen'}>Citizen</MenuItem>
                                                        <MenuItem value={'volcanologist'}>Volcanologist</MenuItem>
                                                        <MenuItem value={'moderator'}>Moderator</MenuItem>
                                                        <MenuItem value={'admin'}>Admin</MenuItem>
                                                    </Select>
                                            </FormControl>

                                            <Box>
                                                <Button onClick={handleCloseRole}>Close</Button> <Button onClick={handleSaveNewRole}>Save</Button>
                                            </Box>
                                        </Box>
                                    </Modal>
                                    
                        <Restricted role="moderator">
                            <Tooltip title="Delete reports on user" placement='right' enterDelay={250}>
                                <MenuItem onClick={handleOpenReport}>
                                    Delete All Reports
                                </MenuItem>
                            </Tooltip>
                            </Restricted>
                                    <Modal
                                        open={openReport}
                                        onClose={handleCloseReport}
                                        aria-labelledby="modal-role-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={modalStyle}>
                                            <Typography id="modal-role-title" 
                                                variant="h6" component="h2">
                                                Deleting Report
                                            </Typography>
                                            <Typography id="modal-modal-description" 
                                                sx={{ mt: 2 }}>
                                                Deleting the reports for {username} will remove all the reports for {username} and return you to the previous page. If you believe action is needed against {username}, then take a different action. If {username} doesn't need any action taken, then delete the reports for {username}'s account.
                                            </Typography>
                                            <Box>
                                                <Button onClick={handleCloseReport}>Close</Button> <Button onClick={deleteUserReport}>Delete</Button>
                                            </Box>
                                        </Box>
                                    </Modal>
                        </Menu>
                    </Box>
                )
            }
        }
        
   

}