import {FormControlLabel, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import * as React from 'react'
import type {Report} from '../Report'
import {REASON_DISPLAY, Reasons} from '../Report'

const ReportInfoInputUser = ({reportInfo, setReportInfo, reason, setReason}: {reportInfo: Report; setReportInfo: (report: Report) => void;
                                                                          reason: string; setReason: (reason: string) => void;}) => {

  const reasons = {content: reportInfo.content, spam: reportInfo.spam, plagiarism: reportInfo.plagiarism, 
    other: reportInfo.other};
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newReason = event.target.value;
    setReportInfo({
      ...reportInfo,
      [reason]: false,
      [newReason]: true,
    });
    setReason(newReason);
  }
  return (
    <div>
      <h3>Reason(s) for report:</h3>
      <FormControl>
        <RadioGroup
          value={reason}
          onChange={update}>
      {
        Object.entries(reasons).map(([reason]) => {
          return (
            <div>
              <span key={reason}>
                <FormControlLabel
                  value={reason}
                  control={<Radio/>}
                  label= {REASON_DISPLAY[reason as keyof Reasons]}
                />
              </span>
            </div>

          )
        })
      }
        </RadioGroup>
      </FormControl>
      
      <h3>Extra comments:</h3>
      <TextField
        name="comments"
        type="text"
        onChange={(e) => {
          setReportInfo({
            ...reportInfo,
            report_comments: e.target.value
          })}}
        placeholder="Comments:"
        multiline
        rows={5}
        />
    </div>
  )
}

export default ReportInfoInputUser