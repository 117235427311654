import * as React from "react";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Link } from "../components/Link";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Fab,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddAPhoto } from "@mui/icons-material";
import { fromFullRawPost, makeRawFullPost, Post } from "../Post";
import { VolcanoNameSearch } from "../components/VolcanoNameSearch";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useVolcanoesWithPosts, useAllVolcanoes, Volcano } from "../Volcano";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "../style/splide.css";
import { describeObservation } from "../Observation";
import Popup from "../components/Popup";
import { CurrentUserContext, Restricted } from "../utils/permissions";
import { useGeolocation } from "../utils/useGeolocation";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import redPin from "../images/redPin.png";
import greyPin from "../images/greyPin.png";
import { useFetch } from "../utils/useFetch";
import { ErrorIndicator } from "../components/ErrorPage";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px",
  rowGap: "10px",
};

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const popup = getPopupFromState(location.state);
  const currentUser = React.useContext(CurrentUserContext);
  const [featured, setFeatured] = React.useState<Post[] | undefined>(undefined);
  const isMobile = useMediaQuery("(max-width: 600px)");

  React.useEffect(() => {
    fetch("/api/posts/featured")
      .then((res) => res.json())
      .then((raw: unknown[]) => {
        return Promise.all(raw.map(fromFullRawPost));
      })
      .then(setFeatured);
  }, []);

  return (
    <main
      style={{
        margin: "30px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        maxWidth: "min(480px, 100vw)",
        rowGap: "20px",
      }}
    >
      {popup === undefined ? undefined : <Card sx={cardStyle}>{popup}</Card>}
      {
        <Box
          component="h1"
          sx={{
            margin: "16px 24px",
            fontSize: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <span style={{ whiteSpace: "nowrap" }}>Welcome to</span>
          <span style={{ whiteSpace: "nowrap" }}>Did You See It!</span>
          {currentUser.role === "public" ? undefined : (
            <>
              <br />
              <span>
                {"Hello, "}
                <Link to="/myprofile">{currentUser.username}</Link>
              </span>
            </>
          )}
        </Box>
      }

      <Card
        sx={{
          //width: "100%",
          margin: 2, //MUI, 2 = 16px
          // height: "360px",
          ...cardStyle,
          padding: "40px 5px",
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>*/}
          Recent Posts
        </Typography>
        {featured === undefined ? (
          <Box
            sx={{
              height: "220px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Splide
            style={{
              padding: "0 2.5em 0",
            }}
            options={{
              width: "100%",
              height: "200px",
              lazyLoad: "nearby",
            }}
          >
            {featured.map((post) => (
              <SplideSlide key={post.id}>
                <div
                  style={{
                    height: "200px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <RouterLink
                    to={`/post/${post.id}`}
                    state={{ viewingPost: makeRawFullPost(post) }}
                    style={{
                      width: "50%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={String(post.observation.photo)}
                      alt=""
                      data-splide-lazy={String(post.observation.photo)}
                    />
                  </RouterLink>
                  <div
                    style={{
                      width: "50%",
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "center",
                      alignItems: "center",
                      textAlign: "center",
                      rowGap: "5px",
                    }}
                  >
                    <span>
                      {post.created.toLocaleString({ dateStyle: "long" })}
                    </span>
                    <span>{post.volcano.name}</span>
                    <span>{describeObservation(post.observation)}</span>

                    <span>
                      <Link
                        to={`/post/${post.id}`}
                        state={{ viewingPost: makeRawFullPost(post) }}
                      >
                        See more
                      </Link>
                    </span>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        )}
      </Card>

      <Card
        sx={{
          margin: 2,
          ...cardStyle,
        }}
      >
        <VolcanoNameSearch
          onSubmit={(volcano) => navigate(`/volcano/${volcano}`)}
        />
      </Card>

      <Card
        sx={{
          margin: 2,
          ...cardStyle,
          padding: "20px",
        }}
      >
        <VolcanoMap />
      </Card>

      <Restricted role="citizen">
        <Link to="/post" color="inherit">
          <Card
            sx={{
              height: "150px",
              margin: 2,
              ...cardStyle,
            }}
          >
            <Box
              sx={{
                width: "64px",
                height: "64px",
                backgroundColor: "#ddd",
                borderRadius: "50%",
                padding: "16px",
              }}
            >
              <AddAPhoto sx={{ width: "32px", height: "32px" }} />
            </Box>
            <Box>Upload a Photo</Box>
          </Card>
        </Link>
      </Restricted>
      {isMobile && (
        <Tooltip title="Install as an app">
          <Fab
            style={{
              position: "fixed",
              bottom: "5%",
              right: "5%",
              color: "white",
              backgroundColor: "darkred",
            }}
            onClick={() => {
              navigate("/about/#install");
            }}
          >
            <InstallMobileIcon />
          </Fab>
        </Tooltip>
      )}
      {!isMobile && (
        <Tooltip title="Install as an app">
          <Fab
            style={{
              position: "fixed",
              right: "5%",
              bottom: "5%",
              minWidth: "80px",
              minHeight: "80px",
              maxWidth: "100px",
              maxHeight: "100px",
              color: "white",
              backgroundColor: "darkred",
            }}
            onClick={() => {
              navigate("/about/#install");
            }}
          >
            <InstallMobileIcon
              style={{
                width: "45%",
                height: "45%",
              }}
            />
          </Fab>
        </Tooltip>
      )}
    </main>
  );
}
function getPopupFromState(
  state: Record<string, unknown> | null
): JSX.Element | undefined {
  let isOpen = true;
  function toggle() {
    isOpen = false;
  }
  if (state == null) {
    return undefined;
  }

  if ("ref" in state) {
    if (state["ref"] === "postSubmitted") {
      isOpen = true;
      return (
        <div>
          <Popup
            title="Post Submission"
            open={isOpen}
            content={
              <>
                <p>
                  Thank you for your contribution. We will review your post as
                  soon as possible.
                </p>
                <form onSubmit={toggle}>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    Close
                  </Button>
                </form>
              </>
            }
          />
        </div>
      );
    }
  }

  return undefined;
}

function VolcanoMap() {
  const volcanoes = useAllVolcanoes();
  const location = useGeolocation();
  const navigate = useNavigate();
  const key = useFetch("/api/gvp/mapsApiKey", { map: (v) => v as string });
  const mapContainerStyle = { width: "min(400px, 100vw - 50px)", height: "400px" };
  const indicatorContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...mapContainerStyle,
  };
  const center =
    location.step === "done"
      ? { lat: location.result.lat, lng: location.result.long }
      : { lat: -36.840556, lng: 174.74 };

  let postVolcanoes = useVolcanoesWithPosts().map(Number);

  switch (key.step) {
    case "fetching":
      return (
        <div style={indicatorContainerStyle}>
          <CircularProgress />
        </div>
      );
    case "error":
      return (
        <div style={indicatorContainerStyle}>
          <ErrorIndicator
            header="Something went wrong loading the map."
            body=""
          />
        </div>
      );
    case "done":
      return (
        <LoadScript googleMapsApiKey={key.value}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={5}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeId: "terrain",
            }}
            clickableIcons={false}
          >
            {volcanoes &&
              volcanoes.map((volcano) => (
                <VolcanoMarker
                  key={volcano.id}
                  volcano={volcano}
                  postVolcanoes={postVolcanoes}
                  onClick={() => navigate(`/volcano/${volcano.id}`)}
                />
              ))}
          </GoogleMap>
        </LoadScript>
      );
  }
}

function VolcanoMarker({
  volcano,
  postVolcanoes,
  onClick,
}: {
  volcano: Volcano;
  postVolcanoes: number[];
  onClick?: () => void;
}) {
  const hasPosts = postVolcanoes.includes(volcano.id);
  return (
    <Marker
      onClick={onClick}
      position={{ lat: volcano.location.lat, lng: volcano.location.long }}
      icon={{
        url: hasPosts ? redPin : greyPin,
        scaledSize: new window.google.maps.Size(40, 40),
      }}
    />
  );
}
