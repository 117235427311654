import { Box } from "@mui/system";
import { Card } from "@mui/material";
import * as React from "react";
import FeedbackForm from "../components/FeedbackForm";

export default function Feedback() {

    return (
        <main>
            <br/>
            <Card  
                sx={{
                    p: 2,
                    m: 2,
                }}
                >
                <h2>Feedback and Bug Reports</h2>
                <Box sx={{ maxWidth: 'sm' }}>
                    Did You See It is currently still in development,
                    thank you for taking the time contribute to this project.
                    We appreciate hearing about any feedback you have or problems you encounter.<br/>
                </Box>
                <br/>
                <FeedbackForm/>
            </Card>
        </main>
    )
};