import { DateTime } from "luxon";
import type { Role } from "./utils/permissions";
export type contactOptions = "phone" | "email" | "both";

export type UserInfo = {
  user_id : number;
  email : string;
  username : string;
  password : string;
  re_password : string;
  delete_request_date: DateTime;
  preferred_contact: contactOptions;
  phone: string;
  country_code: string;
  role: Role;
};



export function defaultUserInfo(): UserInfo {
  
  return {
    user_id : 0,
    email : "",
    username : "",
    password : "",
    re_password : "",
    delete_request_date: DateTime.utc(3005, 1, 1, 0, 0),
    preferred_contact: "email",
    phone: "",
    country_code: "",
    role: "public",
    };
}