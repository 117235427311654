import * as React from "react";
import MuiLink from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import type { LinkTypeMap } from "@mui/material";

export function Link({
  to,
  state,
  children,
  ...props
}: {
  to: string;
  state?: any;
  children?: React.ReactNode;
} & LinkTypeMap["props"]) {
  return (
    <MuiLink component="span" {...props}>
      <RouterLink
        to={to}
        state={state}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        {children}
      </RouterLink>
    </MuiLink>
  );
}