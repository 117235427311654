import * as React from 'react';
import { CircularProgress, Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ChangeUserRoleDialog = (props: {targetUserid: any, targetUser: any, targetRole: any, open: boolean, handleClose: () => void}) => {

    let targetUserid = props.targetUserid
    let targetUser = props.targetUser
    let targetRole = props.targetRole
    
    const [newRole, setNewRole] = React.useState(targetRole)
    const handleNewRole = (event: SelectChangeEvent) => {
        setNewRole(event.target.value);
    };

    const [pageState, setPageState] = React.useState('open');

    let handleSubmit = () => {
        setPageState('submitting')
        handleSaveNewRole()
    }

    let handleSaveNewRole = async () => {
        fetch(`/api/users/role/`, {

          method: 'PATCH',
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            targetID: targetUserid,
            newRole: newRole,
          })
        })
        .then((response) => {
            if(response.ok){
                setPageState('success')
                return response
              }
              else{
                setPageState('error')
                throw response
              }
        })
    }
    
    return (

        <Dialog open={props.open} onClose={props.handleClose}>

            <DialogTitle>
                <Typography variant="h4" fontSize={25}>Change Role</Typography>
                <IconButton
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    { pageState === 'open' &&
                        <div>
                            <Typography gutterBottom>Changing this user's role will change what permissions they have on Did You See It. Make sure you only assign users the lowest role they need.</Typography>
                            <Typography>Username: {targetUser}</Typography>
                            <Typography>Role: {targetRole}</Typography>


                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                <InputLabel>Set new role</InputLabel>
                                    <Select
                                        labelId='user-role-select-label'
                                        id='user-role-select'
                                        value={newRole}
                                        label="Role"
                                        onChange={handleNewRole}
                                    >
                                        <MenuItem value={'citizen'}>Citizen</MenuItem>
                                        <MenuItem value={'volcanologist'}>Volcanologist</MenuItem>
                                        <MenuItem value={'moderator'}>Moderator</MenuItem>
                                        <MenuItem value={'admin'}>Admin</MenuItem>
                                    </Select>
                            </FormControl>

                        </div>
                    }

                    { pageState === 'submitting' &&
                        <div>
                            <CircularProgress />
                            <Typography>
                                Sending request.
                            </Typography>
                        </div>
                    }

                    { pageState === 'success' &&
                        <Typography>
                            {targetUser}'s role has successfully changed from {targetRole} to {newRole}.
                        </Typography>
                    }

                    { pageState === 'error' &&
                        <Typography>
                            Something went wrong, please try again later.
                        </Typography>
                    }
                </DialogContentText>

                <DialogActions>
                    { pageState !== 'success' && <Button onClick={props.handleClose}> Cancel </Button> }
                    { pageState === 'success' && <Button onClick={props.handleClose}> Ok </Button> }
                    { pageState === 'open' && <Button onClick={handleSubmit}> Save </Button> }
                    { pageState === 'submitting' && <Button disabled> Save </Button> }
                </DialogActions>
            </DialogContent>
        </Dialog>
        )
}

export default ChangeUserRoleDialog