import { useContext } from "react";
import { CurrentUserContext } from "./permissions";

export function useConversions() {
  const currentUser = useContext(CurrentUserContext);
  return {
    km(km: number): string {
      let distNum = km;
      let unit = "km";
      if (currentUser.role !== "public" && !currentUser.metric) {
        distNum *= 0.62137;
        unit = "mi.";
      }
      return `${displayLength(distNum)} ${unit}`;
    },
    m(m: number): string {
      let distNum = m;
      let unit = "m";
      if (currentUser.role !== "public" && !currentUser.metric) {
        distNum *= 3.2808;
        unit = "ft";
      }
      return `${displayLength(distNum)} ${unit}`;
    },
  };
}

function displayLength(n: number): number {
  if (n < 10) {
    return Math.round(n * 10) / 10;
  } else {
    return Math.round(n);
  }
}