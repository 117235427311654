import * as React from "react";
import { Avatar, Box, Button } from "@mui/material";
import { comparePosts, fromRawPost } from "../Post";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ErrorIndicator } from "../components/ErrorPage";
import "../style/reportTable.css";
import { DateTime } from "luxon";
import type { ReportDetail } from "../Report";
import { UserMenuForReportedUser } from "../components/UserMenuForReportedUser";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { LogoIcon } from "../images/LogoIcon";
import { PostGallery } from "../components/PostGallery";

function ReportedUserProfile() {
  const { state } = useLocation();
  const { uid } = state;

  const [username, setUsername] = React.useState<string>();
  const [reportInfo, setReportInfo] = React.useState<ReportDetail[]>();
  const [focusedPost, setFocusedPost] = React.useState<string>();
  const [posts] = React.useState(() =>
    fetch(`/api/posts/userposts/${uid}`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(async (rawPosts: Array<unknown>) => {
        const posts = await Promise.all(rawPosts.map(fromRawPost));
        posts.sort(comparePosts);
        return posts;
      })
  );

  React.useEffect(() => {
    const getData = setTimeout(() => {
      //Timer to load data. Sometime, data isn't loaded and screen is blank and need constant refresh.
      fetch(`/api/reports/${uid}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then(async (json) => {
          setReportInfo(json);
          /*
          console.log("test", reportInfo?.find((obj) => {
            return obj.report_id === 85 //for jackfinaltest
          }))
          */
          console.log("report list", json);
        });

      fetch(`/api/users/${uid}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          setUsername(json.username);
        });
    }, 3000);
    return () => clearTimeout(getData);
  }, [uid]);

  const dateFormat = (date: any) => {
    const format = DateTime.fromISO(date);
    //console.log(date)
    const humanReadable = format.toLocaleString();
    return humanReadable;
  };

  const columns: GridColDef[] = [
    {
      field: "report_id",
      headerName: "Id",
      width: 90,
    },
    {
      field: "content",
      headerName: "Content",
      type: "boolean",
      width: 150,
      //editable: true,
    },
    {
      field: "spam",
      headerName: "Spam",
      type: "boolean",
      width: 150,
      //editable: true,
    },
    {
      field: "plagiarism",
      headerName: "Plagiarism",
      type: "boolean",
      width: 110,
      //editable: true,
    } /*
    {
      field: 'ai_generated',
      headerName: 'AI Generated',
      type: 'boolean',
      width: 110,
      //editable: true,
    },
    {
      field: 'stock_photo',
      headerName: 'Stock Photo',
      type: 'boolean',
      width: 110,
      //editable: true,
    },*/,
    {
      field: "other",
      headerName: "Other",
      type: "boolean",
      width: 110,
      //editable: true,
    },
    {
      field: "report_comments",
      headerName: "Comment",
      type: "string",
      width: 110,
      //editable: true,
    },
    /* {
      field: 'report_datetime',
      headerName: 'Report Date',
      type: 'date',
      width: 110,
      editable: true,
    }, */
    {
      field: "date",
      headerName: "Date",
      //description: 'This column has a value getter and is not sortable.',
      sortable: false,
      type: "date",
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${dateFormat(params.row["report_datetime"])}`,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <Button onClick={() => deleteSingleReport(params.row.report_id)}>
            Delete
          </Button>
        );
      },
    },
  ];

  async function deleteSingleReport(pid: number) {
    fetch(`/api/reports/deleteSingleReport/${pid}/`, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.ok) {
        window.location.reload();
      }
    });
  }

  if (username === undefined || reportInfo === undefined) {
    return <CircularProgress sx={{ marginTop: "50%" }} />;
  }

  return (
    <main style={{
      width: "100%",
      padding: "0 20px",
    }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Reported User: {username}</h1>
        <Avatar sx={{ m: 1, bgcolor: "darkred" }}>
          <LogoIcon />
        </Avatar>

        <UserMenuForReportedUser
          username={username}
          userid={uid}
          postid={focusedPost}
        />
      </Box>

      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={reportInfo!}
          getRowId={(row: any) => row.report_id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          //disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main.light",
            },
          }}
        />
      </Box>
      <PostGallery
        posts={posts}
        galleryTitle={<h2 style={{ textAlign: "center"}}>{username}'s posts</h2>}
        emptyListPlaceholder={
          <ErrorIndicator
            icon={<LogoIcon style={{ width: "100%", height: "100%" }} />}
            header={`${username} hasn't made any posts yet.`}
            body=""
          />
        }
        onMoved={(post) => setFocusedPost(post.id)}
      />
    </main>
  );
}
export default ReportedUserProfile;
