import { Search } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import * as React from "react";

export function SearchList({
  label,
  setQuery,
  items,
  emptyPlaceholder,
  loadingPlaceholder,
}: {
  label: string;
  setQuery: (query: string) => void;
  items: React.ReactNode[] | undefined;
  emptyPlaceholder: React.ReactNode;
  loadingPlaceholder?: string;
}) {
  let content;
  if (items === undefined) {
    content = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          gap: "20px",
        }}
      >
        <CircularProgress />
        <span style={{ color: "#999" }}>
          {loadingPlaceholder ?? "Loading..."}
        </span>
      </div>
    );
  } else {
    
    let list: React.ReactNode = items;

    if (items.length === 0) {
      list = <div style={{ color: "#999" }}>{emptyPlaceholder}</div>;
    }

    content = (
      <>
        <TextField
          label={label}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          style={{ width: "100%" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "8px",
            padding: "8px",
          }}
        >
          {list}
        </div>
      </>
    );
  }

  return (
    <div
      style={{
        height: "385px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {content}
    </div>
  );
}
