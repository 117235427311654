import Link from "@mui/material/Link";
import type { IDBPDatabase } from "idb";
import * as React from "react";
import {
  distance,
  getVolcanoes,
  openVolcanoDb,
  useNearbyVolcanoes,
  VolcanoDb,
  VolcanoResult,
} from "../Volcano";
import { SearchList } from "./SearchList";
import { useConversions } from "../utils/conversions";

const RESULT_COUNT = 10;

export function VolcanoNameSearch({
  onSubmit,
}: {
  onSubmit: (volcano: number) => void;
}) {
  const [query, setQuery] = React.useState<string>("");
  const nearbyVolcanoes = useNearbyVolcanoes({
    distance: 1000,
    count: RESULT_COUNT - 1,
  });
  const [volcanoes, setVolcanoes] = React.useState<VolcanoResult[]>([]);
  const [db, setDb] = React.useState<IDBPDatabase<VolcanoDb> | undefined>(
    undefined
  );
  const [header, setHeader] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const conversions = useConversions();

  React.useEffect(() => {
    openVolcanoDb().then((db) => setDb(db));
  }, []);

  React.useEffect(() => {
    if (query === "") {
      if (nearbyVolcanoes.step === "done") {
        if (nearbyVolcanoes.volcanoes.length > 0) {
          setVolcanoes(nearbyVolcanoes.volcanoes);
          setHeader(<div key="nearby volcanoes">Nearby volcanoes:</div>);
        } else {
          setHeader(
            <div key="nearby volcanoes">There are no nearby volcanoes. Search for a volcano by name using the search box.</div>
          );
        }
      } else {
        setVolcanoes([]);
        setHeader(undefined);
      }
    } else if (db !== undefined) {
      setHeader(undefined);
      getVolcanoes(query, RESULT_COUNT).then(setVolcanoes);
    }
  }, [db, query, nearbyVolcanoes]);

  const items =
    db === undefined
      ? undefined
      : volcanoes.map((volcano) => {
          const dist =
            nearbyVolcanoes.step === "done"
              ? conversions.km(
                  distance(nearbyVolcanoes.location, volcano.location)
                )
              : "";

          return (
            <Link
              key={volcano.alias ?? volcano.name}
              onClick={() => onSubmit(volcano.id)}
              style={{
                display: "block",
                width: "100%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <span style={{ cursor: "pointer" }}>
                {volcano.alias !== undefined
                  ? `${volcano.alias} (${volcano.name})`
                  : volcano.name}
              </span>
              <span
                style={{
                  color: "#555",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
              >
                {dist}
              </span>
            </Link>
          );
        });

  if (header !== undefined && items !== undefined) {
    items.unshift(header);
  }

  let emptyMessage;

  if (query === "") {
    if (nearbyVolcanoes.step === "waiting") {
      emptyMessage = "Searching for nearby volcanoes...";
    } else {
      emptyMessage = "Couldn't find nearby volcanoes.";
    }
  } else {
    emptyMessage = "Couldn't find any volcanoes.";
  }

  return (
    <SearchList
      label="Search for a volcano"
      setQuery={setQuery}
      items={items}
      emptyPlaceholder={emptyMessage}
      loadingPlaceholder="Downloading volcano database..."
    />
  );
}