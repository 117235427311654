import { Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePost } from "../utils/usePost";
import { PostReviewView } from "../components/PostReviewView";
import Popup from "../components/Popup";

export function ReportedPostPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const postResult = usePost(Number(id));

  const [isOpenReport, setIsOpenReport] = React.useState(false);
  const togglePopupReport = () => {
    setIsOpenReport(!isOpenReport);
  };
  const [isOpenPost, setIsOpenPost] = React.useState(false);
  const togglePopupPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const [confirmDeletePost, setConfirmDeletePost] = React.useState(false);
  const toggleConfirmDeletePost = () => {
    setConfirmDeletePost(!confirmDeletePost);
  };

  async function deleteReportPost(pid: any) {

    fetch(`/api/reports/deleteReportPost/${pid}/`, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          console.log(res);
          setIsOpenReport(true);
          setTimeout(() => navigate(-1), 4000);
          return res.json();
        } else {
          throw res;
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  async function deletePost(pid: any) {
    fetch(`/api/reports/deletePost/${pid}/`, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          setIsOpenPost(true);
          toggleConfirmDeletePost();
          setTimeout(() => navigate(-1), 4000);
          console.log(res);
         
          return res.json();
        } else {
          throw res;
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }
  switch (postResult.step) {
    case "fetching":
      return (
        <main>
          <CircularProgress />
        </main>
      );
    case "done":
      return (
        <main>
            <h1>Reviewing Reported Post</h1>
            <Popup
            title = "Delete Post"
            open={confirmDeletePost}
            onClose={toggleConfirmDeletePost}
            content={<>
            <p>
              Do you want to delete this post? <Button onClick={() => deletePost(id)}> Yes </Button> <Button onClick={toggleConfirmDeletePost}> No </Button> 
            </p>
            </>}
          />
          <Popup
              title="Deletion"
              open={isOpenReport}
              onClose={togglePopupReport}
              content={
                <>
                  <p>All reports associated with this post are now deleted. Redirecting now...</p>
                </>
              }
            />
            <Popup
              title="Deletion"
              open={isOpenPost}
              onClose={togglePopupPost}
              content={
                <>
                  <p>The selected post is deleted. Redirecting now...</p>
                </>
              }
            />
          <PostReviewView
            post={postResult.value}
            onBack={() => navigate(-1)}
            overlay={
              <>
              <br/>
              <br/>
                <Button onClick={toggleConfirmDeletePost}>
                  {" "}
                  Delete Post{" "}
                </Button>

                <br/>
                <br/>
                <Button
                  onClick={() =>
                    deleteReportPost(id)
                  }
                >
                  {" "}
                  Delete Report{" "}
                </Button>
                <br/>
                <br/>
              </>
            }
          />

        </main>
      );
    case "error":
      return (
        <main>
          <div>Something went wrong loading that post.</div>
        </main>
      );
  }
}