import * as React from "react";
import { ErrorPage } from "../components/ErrorPage";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { Box, Button, CircularProgress } from "@mui/material";
import type { ReportDetail } from "../Report";
import { comparePosts, fromRawPost, Post } from "../Post";
import { Link } from "../components/Link";
import { LogoIcon } from "../images/LogoIcon";


type State =
  | {
    step: "fetching";
  }
  | {
    step: "list";
  }
  | {
    step: "selected";
    posts: Array<Post>;
    page: number;
  }
  | {
    step: "error";
    error: unknown;
  };


function ViewReportPost () {
    const [state, setState] = React.useState<State>({ step: "fetching" });
    const [reportInfo, setReportInfo] = React.useState<ReportDetail[]>(); //List of reports 

    const [posts, setPosts] = React.useState<Post[]>();

    //const navigate = useNavigate();
    
    React.useEffect(() => {
      const getData = setTimeout(() => {
        fetch(`/api/reports/allPostReports/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => res.json())
          .then(async (json) => {
            setReportInfo(json)
            /*
            console.log("test", reportInfo?.find((obj) => {
              return obj.report_id === 85 //for jackfinaltest
            }))
            */
            console.log('report list', json);
          });

          fetch(`/api/reports/getAllReportedPost/`, {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                throw res;
              }
            })
            .then(async (rawPosts: Array<unknown>) => {
              const posts = await Promise.all(rawPosts.map(fromRawPost));
              posts?.sort(comparePosts);
              setPosts(posts);
              console.log('post', posts);
              setState({ step: "list"});
            })
            .catch((error) => {
              setState({ step: "error", error });
              console.error("Error fetching user posts: ", error);
            });

          
      }, 500);
      return () => clearTimeout(getData);
              
    }, [])
 

    const dateFormat = (date:any) =>{
        const format = DateTime.fromISO(date)
        const humanReadable = format.toLocaleString();
        return humanReadable;
      }
    
    const displayImage = (uid: number) => {
      return posts?.find((obj) => Number(obj.id) === uid)?.observation?.photo?.toString();
    }
    
      const columns: GridColDef[] = [
        { 
          field: 'report_id', 
          headerName: 'Id', 
          width: 90,
        },
        {
            field: 'reported_post',
            headerName: 'Post Id',
            type: 'number',
            width: 90,
            renderCell: (params) => {
              return (
                <Link
                to={`/reviewpost/${params.row.reported_post}`}>
                  {params.row.reported_post}
                </Link>
              )
            }
          },
          
        {
            field: 'image',
            headerName: 'Photo',
            width: 160,
            renderCell: (params) => {
              return (
                <Link
                to={`/reviewpost/${params.row.reported_post}`}>
            <img src={displayImage(params.row.reported_post) } alt="post contents"
            style={{
              flex: "1 0 400px",
              maxWidth: "100%",
              objectFit: "contain",
            }} />
            </Link>
              )
          },
          },   
            
        {
          field: 'content',
          headerName: 'Content',
          type: 'boolean',
          width: 150,
          //editable: true,
        },
        {
          field: 'spam',
          headerName: 'Spam',
          type: 'boolean',
          width: 150,
          //editable: true,
        },
        {
          field: 'plagiarism',
          headerName: 'Plagiarism',
          type: 'boolean',
          width: 110,
          //editable: true,
        },
        {
          field: 'ai_generated',
          headerName: 'AI Generated',
          type: 'boolean',
          width: 110,
          //editable: true,
        },
        {
          field: 'stock_photo',
          headerName: 'Stock Photo',
          type: 'boolean',
          width: 110,
          //editable: true,
        },
        {
          field: 'other',
          headerName: 'Other',
          type: 'boolean',
          width: 110,
          //editable: true,
        },
        {
          field: 'report_comments',
          headerName: 'Comment',
          type: 'string',
          width: 110,
          //editable: true,
        },
        /* {
          field: 'report_datetime',
          headerName: 'Report Date',
          type: 'date',
          width: 110,
          editable: true,
        }, */
        {
          field: 'date',
          headerName: 'Date',
          //description: 'This column has a value getter and is not sortable.',
          sortable: false,
          type: 'date',
          width: 160,
          valueGetter: (params: GridValueGetterParams) =>
            `${dateFormat(params.row["report_datetime"])}`,
        },
        {
          field: 'action',
          headerName: 'Action',
          sortable: false,
          width: 160,
          renderCell: (params) => {
            return (
              <Button
                onClick={() => deleteSingleReport(params.row.report_id)}
                variant="contained"
              >
                Delete
              </Button>
            );
          } 
        },
     
      ];
    
      async function deleteSingleReport(pid: number) {
        fetch(`/api/reports/deleteSingleReport/${pid}/`, {
            method: 'GET',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        })
        .then((res) => {
            if(res.ok) {
              window.location.reload();
    
            }
        })     
    }

    switch (state.step) {
        case "fetching":
            return <CircularProgress sx={{ marginTop: "50%" }}/>
        case "list":
            if (reportInfo === undefined || reportInfo.length === 0) {
                return (
                    <ErrorPage
                      icon={<LogoIcon sx={{width: '100%', height: '100%'}}/>}
                      header="There are no reported posts to review right now."
                      body="Check back later."
                    />
                )

            }
            return(
                <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={reportInfo!}
              getRowId={(row:any) => row.report_id}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              //checkboxSelection
              //disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main.light',
                },
              }}
            />
          </Box>
            )

    }
    return (
      <></>
    )
}

export default ViewReportPost;
