import { Box, useMediaQuery} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';

const RegistrationSuccess = () => {

  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [message, setMessage] = useState([""]);
  const [dialogHeading, setDialogHeading] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogHeading('');
    setMessage([""]);
  }
  let resendActivation = async () => {
    setMessage([""]);

    fetch(`/auth/users/resend_activation/`, {
      method : "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({email: email})
    }).then((response) => {
      if(response.ok){
        setDialogHeading("Email Resent");
        setMessage((message) => 
        [...message, `Another activation email has been sent to ${email}. If you are having
        issues finding the email, try looking through your spam/junk folder.`]);
        setOpenDialog(true);
        return response.json();
      }
      else {
        setDialogHeading("Activation Failed");
        setMessage((message) =>
          [...message, `Error: ${response.status} ${response.statusText}.`]);
        setOpenDialog(true);
        throw response;
      }}).catch((error) => {
      console.log(error)
    })
    
  }

  return (
    <div>
      <div>
        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        >
          <DialogTitle>
            {dialogHeading}
          </DialogTitle>
          <DialogContent>
            {message.join(' ')}
          </DialogContent>
        </Dialog>
      </div>
      <Box
      sx = {{
        alignItems: isMobile ? 'center' : 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        margin: isMobile ? 'auto' : '0',
        marginRight: 'auto',
        maxWidth: '100%',
        width: '90%',
        position: isMobile ? 'relative' : 'absolute',
        top: isMobile ? '' : '10vh',
        left: isMobile ? '' : '15vh'

      }}>

        
        <h3
          style = {{
            fontSize: isMobile ? '22px' : '30px'

          }}>Registration Successful.</h3>
        <Box
        sx={{
          width: isMobile ? 350 : '90%',
          height: 2,
          backgroundColor: 'primary.dark',
          opacity: '75%'
        }}/>

        <div
        style = {{
          display: isMobile ? 'flex' : '', 
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: isMobile ? 'center' : 'left'
        }}>

          <p
          style = 
          {{
            fontSize: isMobile ? '16px' : '20px',
            margin: isMobile ? '15px auto' : '',
            maxWidth: isMobile ? '90%' : '85vw',
            width: isMobile ? '90%' : '100%',

          }}>
            {`An activation email has been sent to ${email}.
            Please proceed to your email to complete 
            registration.`}</p>

          <Button
            style = {{
              width: isMobile ? '150px' : '200px',
              marginTop : isMobile ? '20px' : ''
            }}
            onClick = {() => {
              resendActivation();
            }}>
              Resend Activation
          </Button>

        

        </div>
        


      </Box>
      
    </div>
  );
}

export default RegistrationSuccess;
