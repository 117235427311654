import { DateTime } from "luxon";
import {
  Observation,
  fromRawObservation,
  makeRawWipObservation,
  RawFullObservation,
} from "./Observation";
import { fromRawVolcano, getVolcano, makeRawVolcano, Volcano } from "./Volcano";
import { fromRawWeather, RawWeather, Weather } from "./Weather";

export type Location = {
  lat: number;
  long: number;
};

export type Status = "uploaded" | "pending" | "live" | "reported";

export type Post = {
  id: string;
  observation: Observation;
  weather?: Weather;
  volcano: Volcano;
  created: DateTime;
  uploadedBy: string;
  verifiedBy: string;
  status: Status;
};

type RawPostBase = {
  post_id: string;
  uploaded_by: string;
  verified_by: string;
  verification_status: Status;
  created_time: string;
};

type RawPost = RawPostBase & {
  observation_id: string;
  weather_id?: string;
};

export type RawFullPost = RawPostBase & {
  observation_id: RawFullObservation;
  weather_id?: RawWeather;
};

export function makeRawPost({
  id,
  observation,
  weather,
  created,
  uploadedBy,
  verifiedBy,
  status,
}: Post): unknown {
  const flat: RawPost = {
    post_id: id,
    observation_id: observation.id,
    weather_id: weather? weather.id : undefined,
    uploaded_by: uploadedBy,
    verified_by: verifiedBy,
    verification_status: status,
    created_time: created.toISO(),
  };

  return flat;
}

export function makeRawFullPost({
  id,
  observation,
  weather,
  created,
  uploadedBy,
  verifiedBy,
  status,
  volcano,
}: Post): RawFullPost {
  const rawObservation = makeRawWipObservation(
    observation
  ) as RawFullObservation;
  rawObservation.observation_id = observation.id;
  rawObservation.volcano_number = makeRawVolcano(volcano);
  return {
    post_id: id,
    observation_id: rawObservation,
    weather_id: {
      weather_id: weather?.id,
      weather_datetime: weather?.datetime?.toISO(),
      humidity: weather?.humidity,
      pressure: weather?.pressure,
      temperature: weather?.temperature,
      wind_direction: weather?.windDirection,
      wind_speed: weather?.windSpeed,
    },
    uploaded_by: uploadedBy,
    verified_by: verifiedBy,
    verification_status: status,
    created_time: created.toISO(),
  };
}

export async function fromRawPost(untyped: unknown): Promise<Post> {
  const raw = untyped as RawPost;
  let weather: Weather | undefined;
  if(raw.weather_id){
    try{
      const untypedWeather = await fetch(`/api/weather/data/${raw.weather_id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      weather = fromRawWeather(untypedWeather);
    } catch(err: any) {
      if(err.status === 404){
        console.log('No weather data')
      }
    };

  }
  

  const observation: Observation = await fetch(
    `/api/posts/observation/${raw.observation_id}`
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    })
    .then((untyped) => fromRawObservation(untyped));

  const volcano = await getVolcano(observation.volcano);

  if (!volcano) {
    throw new Error(`Unknown volcano ${observation.volcano}`);
  }

  return {
    id: raw.post_id,
    weather,
    volcano,
    created: DateTime.fromISO(raw.created_time, { setZone: true }),
    status: raw.verification_status,
    uploadedBy: raw.uploaded_by,
    verifiedBy: raw.verified_by,
    observation,
  };
}

export function fromFullRawPost(untyped: unknown): Post {
  const raw = untyped as RawFullPost;
  const post: Post = {
    id: raw.post_id,
    observation: fromRawObservation(raw.observation_id),
    volcano: fromRawVolcano(raw.observation_id.volcano_number),
    created: DateTime.fromISO(raw.created_time, { setZone: true }),
    uploadedBy: raw.uploaded_by,
    verifiedBy: raw.verified_by,
    status: raw.verification_status,
  };
  if (raw.weather_id) {
    post.weather = fromRawWeather(raw.weather_id);
  }
  return post;
}

export function comparePosts(a: Post, b: Post) {
  const dateA = a.observation.datetime;
  const dateB = b.observation.datetime;

  if (dateA < dateB) {
    return -1;
  } else {
    return 1;
  }
}