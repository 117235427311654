import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

const EditAccountDialog = (
    props: 
    {
        username: any, 
        userid: any, 
        email: any, 
        countrycode: any,
        phoneNumber: any,
        contactMethod: any,
        useMetric: any,
        open: boolean, 
        handleClose: () => void}) =>{

    useEffect(() => {
        fetch("/api/users/current/", {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error(
                `Failed to get current user: ${res.status} ${res.statusText}`
              );
            }
          })
          .then((json: any) => {
            setEmail(json['email'])
            setUsername(json['username'])
            setCountryCode(json['country_code'])
            setPhone(json['phone'])
            setContactMethod(json['contact_method'])
            setIsMetric(json['use_metric'])
            setRole(json['role'])
          })
          .catch((e: unknown) => {
            console.error(e);
          });
      }, []);

    interface IUserFormData {
        user_id: string | null;
        username: string;
        phone: string;
        country_code: string;
        preferred_contact: string;
        use_metric: boolean;
    }

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [phone, setPhone] = useState('');
    const [contactMethod, setContactMethod] = useState('email');
    const [isMetric, setIsMetric] = useState(true);
    const [role, setRole] = useState('');

    interface State {
        step: "new" | "submitting" | "success" | "error";
    }

    const [state, setState] = React.useState<State>({ step: 'new'});

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setState({ step: 'submitting' });
        const userData: IUserFormData = {
            user_id: localStorage.getItem('userID'),
            username,
            phone: phone.replace(/\D/g,''),
            country_code: countryCode.replace(/\D/g,''),
            preferred_contact: contactMethod,
            use_metric: isMetric,
        };
        try {
            const response = await fetch(`/api/users/user/`, {
                method: 'PATCH',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });
            const data = await response.json();
            console.log(data);
            setState({ step: 'success' });
        } catch(error) {
            console.log(error);
            setState({ step: 'error' });
        }
    };

    const closeDialog = () => {
        if (state.step === 'success')
        {
            props.handleClose();
            window.location.reload();
        } else {
            setState({ step: 'new' });
            props.handleClose();
        }
        
    }

    return (
        <Dialog open={props.open} onClose={closeDialog}>

            <DialogTitle>
                <Typography variant="h4" fontSize={25}>Edit Account</Typography>
                <IconButton
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} sx={{maxWidth: 'sm'}}>

                        <Grid item xs={4} sm={2} mt={4}>
                            <Typography>Username</Typography>
                        </Grid>

                        <Grid item xs={8} sm={10} mt={2}>
                            <TextField
                            fullWidth
                            variant="outlined"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <Typography>Role</Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            {role}
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <Typography>Email</Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            {email}
                        </Grid>

                        <Grid item xs={12} sm={2} mt={4}>
                            <Typography>Phone</Typography>
                        </Grid>

                        <Grid item xs={4} sm={2} mt={2}>
                            <TextField
                            fullWidth
                            label="CC"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +
                                  </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={8} sm={8} mt={2}>
                            <TextField
                            fullWidth
                            label="Phone Number"
                            variant="outlined"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            />
                        </Grid>
                        
                        <Grid item xs={12}mt={2}>
                            <Typography>Contact Method</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControl component="fieldset">
                            <RadioGroup row aria-label="contactMethod" name="contactMethod" value={contactMethod} onChange={(e) => setContactMethod(e.target.value)}>
                                <FormControlLabel value="phone" control={<Radio />} label="Phone" />
                                <FormControlLabel value="email" control={<Radio />} label="Email" />
                                <FormControlLabel value="both" control={<Radio />} label="Both" />
                                <FormControlLabel value="none" control={<Radio />} label="None" />
                            </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Preferred Units</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="metric" name="metric" value={isMetric} onChange={(e) => setIsMetric(e.target.value === 'true')}>
                                    <FormControlLabel value={true} control={<Radio />} label="Metric" />
                                    <FormControlLabel value={false} control={<Radio />} label="Imperial" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={6}>
                            {state.step === 'success' && <Typography fontWeight={'bold'}>Update successful!</Typography>}
                            {state.step === 'error' && <Typography fontWeight={'bold'}>Update failed. Please try again.</Typography>}
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Button type="submit" disabled={state.step === 'submitting'}>
                                {state.step === 'submitting' ? 'Updating...' : 'Update User Info'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent> 
        </Dialog>
    );
}

export default EditAccountDialog