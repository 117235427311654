import * as React from 'react';
import { Button, Dialog, FormControlLabel, FormGroup, Grid, IconButton,Switch, Typography } from "@mui/material"
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import type { Sights } from '../Observation';


const RemoveObservations = (props: {postid: number , sights: Sights|undefined, open: boolean, handleClose: () => void, handleUpdate: (updatedSights: Sights) => void}) => {
    interface State {
        step: "new" | "submitting" | "success" | "error";
    }

    const [state, setState] = React.useState<State>({ step: 'new'});
    
    const [sightState, setSightState] = React.useState({
        aALavaFlow: props.sights?.aALavaFlow === 'yes' ? true : false,
        lavaFountain: props.sights?.lavaFountain === 'yes' ? true : false,
        slabbyPahoehoeFlow: props.sights?.slabbyPahoehoeFlow === 'yes' ? true : false,
        pelesHair: props.sights?.pelesHair === 'yes' ? true : false,
        laze: props.sights?.laze === 'yes' ? true : false,
        volcanicPlume: props.sights?.volcanicPlume === 'yes' ? true : false,
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSightState({
          ...sightState,
          [event.target.name]: event.target.checked,
        });
      };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setState({ step: 'submitting' });
        const payload = {
            lava_flow: sightState.aALavaFlow === true ? 'yes' : 'no',
            lava_fountain: sightState.lavaFountain === true ? 'yes' : 'no',
            slabby_flow: sightState.slabbyPahoehoeFlow === true ? 'yes' : 'no',
            peles_hair: sightState.pelesHair === true ? 'yes' : 'no',
            laze: sightState.laze === true ? 'yes' : 'no',
            volcanic_plume: sightState.volcanicPlume === true ? 'yes' : 'no',
        };
        try {
            const response = await fetch(`/api/posts/observation/${props.postid}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            props.handleUpdate(data);
            setState({ step: 'success' });
        } catch(error) {
            console.log(error);
            setState({ step: 'error' });
        }
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>
                <Typography variant="h4" fontSize={25}>Update observed sights</Typography>
                <IconButton
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div>
            <FormGroup>
                <form onSubmit={handleSubmit}>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Switch checked={sightState.aALavaFlow} onChange={handleChange} name="aALavaFlow" />}
                            label="AA Lava Flow"
                            />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Switch checked={sightState.lavaFountain} onChange={handleChange} name="lavaFountain" />}
                            label="Lava Fountain"
                            />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Switch checked={sightState.slabbyPahoehoeFlow} onChange={handleChange} name="slabbyPahoehoeFlow" />}
                            label="Slabby Pahoehoe Flow"
                            />
                    </Grid>
                
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Switch checked={sightState.pelesHair} onChange={handleChange} name="pelesHair" />}
                            label="Pele's Hair"
                            />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Switch checked={sightState.laze} onChange={handleChange} name="laze" />}
                            label="Laze"
                            />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Switch checked={sightState.volcanicPlume} onChange={handleChange} name="volcanicPlume" />}
                            label="Volcanic Plume"
                            />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        {state.step === 'success' && <Typography fontWeight={'bold'}>Update successful! Changes will be visible after approving post. You may close close this window.</Typography>}
                        {state.step === 'error' && <Typography fontWeight={'bold'}>Update failed. Please try again.</Typography>}
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <Button type="submit" disabled={state.step === 'submitting'}>
                            {state.step === 'submitting' ? 'Updating...' : 'Update Observations'}
                        </Button>
                    </Grid>
                </Grid>
                </form>
            </FormGroup>
            </div>
        </Dialog>
        )
}

export default RemoveObservations