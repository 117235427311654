import { DateTime } from "luxon";
import * as React from "react";
import { useEffect } from "react";
import Upload from "../components/Upload";
import { VolcanoNameSearch } from "../components/VolcanoNameSearch";
import { defaultObservation, WipObservation } from "../Observation";
import type { Location } from "../Post";
import { PostObservation } from "./PostObservation";
import GeolocationError from "../components/GeolocationError";
import { Card, FormControlLabel, Switch } from "@mui/material";
import { Button, TextField } from "@mui/material";
import Popup from "../components/Popup";
import { ErrorPage } from "../components/ErrorPage";
import { Link } from "../components/Link";
import { BackButtonHeader } from "../components/BackButtonHeader";

type PostState =
  | {
      step: "source";
    }
  | {
      step: "date";
      photo: URL | undefined;
    }
  | {
      step: "volcano";
      photo: URL | undefined;
    }
  | {
      step: "observation";
      observation: WipObservation;
    }
  | {
      step: "error";
      error: unknown;
    };

export function CreatePost() {
  const [state, setState] = React.useState<PostState>({ step: "source" });

  const [currentLocation, setCurrentLocation] = React.useState<Location>();
  const [photoLocation, setPhotoLocation] = React.useState<Location>();
  const [datetime, setDatetime] = React.useState<DateTime>();

  const [pickedDate, setPickedDate] = React.useState<string>();
  const [dateError, setDateError] = React.useState<string>();
  const [missingDatePopupOpen, setMissingDatePopupOpen] = React.useState(false);
  const [timeUnknown, setTimeUnknown] = React.useState(false);

  const [geolocationWarning, setGeolocationWarning] =
    React.useState<React.ReactNode | null>(null);

  function back() {
    switch (state.step) {
      case "source":
        setState({
          step: "error",
          error: new Error("Cannot go back from source step"),
        });
        break;
      case "date":
        setState({ step: "source" });
        break;
      case "volcano":
        // if user never picked a date, they never saw the date step; go back to source
        if (pickedDate === undefined) {
          setState({ step: "source" });
        } else {
          setState({ step: "date", photo: state.photo });
        }
        break;
      case "observation":
        setState({ step: "volcano", photo: state.observation.photo });
        break;
      case "error":
        setState({ step: "source" });
        break;
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        const location = { lat: latitude, long: longitude };
        setCurrentLocation(location);
      },
      () =>
        setGeolocationWarning(
          <Card sx={{ m: 2 }}>
            <GeolocationError />
          </Card>
        )
    );
  }, []);

  useEffect(() => {
    setPhotoLocation(
      checkApplyCurrentLocation(datetime, currentLocation, photoLocation)
    );
  }, [datetime, currentLocation, photoLocation]);

  switch (state.step) {
    case "source":
      return (
        <>
          {geolocationWarning}
          <Upload
            onSubmit={(photo, { datetime, fileModified, location, source }) => {
              if (
                datetime === undefined &&
                source === "take" &&
                Math.abs(fileModified.diffNow("minutes").minutes) < 2
              ) {
                datetime = fileModified;
              }

              setPhotoLocation(
                location ??
                  checkApplyCurrentLocation(
                    datetime,
                    currentLocation,
                    photoLocation
                  )
              );

              if (datetime !== undefined) {
                setDatetime(datetime);
                setState({ step: "volcano", photo });
              } else {
                setMissingDatePopupOpen(true);
                setState({ step: "date", photo });
              }
            }}
          />
        </>
      );

    case "date":
      const closeMissingDatePopup = () => {
        setMissingDatePopupOpen(false);
      };
      const submitDate = () => {
        if (pickedDate !== undefined && pickedDate !== "") {
          const datetime = DateTime.fromISO(pickedDate);
          if (datetime.diffNow().milliseconds > 0) {
            setDateError("Please enter a date and time in the past");
          } else {
            setDatetime(datetime);
            setState({ step: "volcano", photo: state.photo });

            console.log("datetime submit", datetime);
          }
        } else {
          //If user didn't pick date, send popup to pick a date.
          setDateError("Please enter a date and time");
        }
      };
      const toggleSwitchTime = () => {
        setTimeUnknown(!timeUnknown);
        console.log(timeUnknown);
      };
      return (
        <div
          style={{
            margin: "16px",
          }}
        >
          <BackButtonHeader title={"Create a new post"} onBack={back} />
          <Popup
            title="Missing Date"
            open={missingDatePopupOpen}
            onClose={closeMissingDatePopup}
            content={
              <>
                <p>
                  If you are using an iPhone tap{" "}
                  <Link style={{ fontWeight: 500 }} to="/about/#ios-metadata">
                    here
                  </Link>{" "}
                  to learn about why you are seeing this message.
                </p>
                <p>
                  Unfortunately, we couldn't pull the date from the photo.
                  Please enter the date when the photo was taken. If you do not
                  remember the date, go to your photo gallery if you're on
                  mobile or File Explorer if you're on a computer, to retrieve
                  the date of the photo you wish to upload.
                </p>

                <Button
                  onClick={() => {
                    setMissingDatePopupOpen(false);
                    closeMissingDatePopup();
                  }}
                >
                  Ok
                </Button>
              </>
            }
          />

          <div style={{ display: "flex", alignItems: "center", gap: "20px", flexWrap: "wrap" }}>
            <TextField
              id="datetime-local"
              label={timeUnknown ? "Enter Date" : "Enter Date and Time"}
              type={timeUnknown ? "date" : "datetime-local"}
              value={pickedDate}
              onChange={(e) => {
                setDateError(undefined);
                return setPickedDate(e.target.value);
              }}
              error={dateError !== undefined}
              helperText={dateError}
              sx={{ width: 250 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {"   "}
            <FormControlLabel
              control={
                <Switch
                  name="Toggle Time"
                  checked={timeUnknown}
                  onChange={toggleSwitchTime}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="I don't know the time"
            />
          </div>

          <Button
            fullWidth
            onClick={submitDate}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            Date Submit
          </Button>
        </div>
      );

    case "volcano":
      console.log("In Observation, Date: ", datetime);
      console.log("In Observation, GPS: ", photoLocation);
      let onSubmit;
      if (datetime !== undefined) {
        onSubmit = (volcano: number) =>
          setState({
            step: "observation",
            observation: defaultObservation(
              state.photo!,
              photoLocation,
              volcano,
              datetime,
              timeUnknown
            ),
          });
      } else {
        onSubmit = () => {};
      }

      return (
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BackButtonHeader title={"Create a new post"} onBack={back} />
          {geolocationWarning}
          <VolcanoNameSearch onSubmit={onSubmit} />
        </main>
      );
    case "observation":
      return (
        <>
          {geolocationWarning}
          <PostObservation observation={state.observation} onBack={back} />
        </>
      );
    case "error":
      return <ErrorPage />;
  }
}

// check if the provided datetime is recent enough to use the provided
// current location as the location for the observation.
// return the new photo location that should be used.
function checkApplyCurrentLocation(
  datetime: DateTime | undefined,
  currentLocation: Location | undefined,
  photoLocation: Location | undefined
): Location | undefined {
  if (
    photoLocation === undefined &&
    datetime !== undefined &&
    currentLocation !== undefined &&
    Math.abs(datetime.diffNow("minutes").minutes) < 2
  ) {
    return currentLocation;
  } else {
    return photoLocation;
  }
}
