import * as React from 'react';
import { Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, MenuItem, TextField
} from "@mui/material";


const RoleRequest = () => {
  const [formOpen, setFormOpen] = React.useState(false);
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  const handleFormSubmit = () => {
    fetch(`/api/users/roleRequest/`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-type' : 'application/json'
      },
      body: JSON.stringify({name: name, organization: organization, 
        comments: comments, username: username, email: email})
    }).then((response) => {
      if(response.ok){
        setSuccess(true);
        setMessageOpen(true);
        return response.json();
      }
      else{
        throw response;
      }
    }).catch((error) => {
      setErrorMsg(error);
      setMessageOpen(true);
      console.log(error);
    })
    setName('');
    setOrganization('');
    setComments('');
    setFormOpen(false);
  };
  React.useEffect(() => {
    fetch("/api/users/current/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            `Failed to get current user: ${res.status} ${res.statusText}`
          );
        }
      })
      .then((json: any) => {
        setEmail(json['email']);
        setUsername(json['username']);
      })
      .catch((e: unknown) => {
        console.error(e);
      });
  }, []);

  const handleClose = () => {
    setName('');
    setOrganization('');
    setComments('');
    setFormOpen(false);
  }
  return (
    <div>
      <MenuItem onClick={() => setFormOpen(true)}>Role Request</MenuItem>

      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle>Request to change your role within Did You See It</DialogTitle>
        <DialogContent>
          <div>
            <h4>
              By filling out this form, you are requesting permission to access other features used by specialists to
              verify the validity of content being uploaded onto this website. Please enter your information below and
              our team will get back to you as soon as possible.
            </h4>
            <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
              <TextField
                margin="normal"
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
              <TextField
                margin="normal"
                label="Organization"
                value={organization}
                onChange={(e: any) => setOrganization(e.target.value)}
              />
              <TextField
                margin="normal"
                label="Comments"
                multiline
                rows={4}
                value={comments}
                onChange={(e: any) => setComments(e.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Go Back</Button>
          <Button onClick={handleFormSubmit} disabled={!name || !organization} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={messageOpen}
        onClose={() => setMessageOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        > 
        {success && (
              <div>
                <DialogTitle>Form Submitted</DialogTitle>
                <DialogContent>
                  <div>
                    <h4>
                      Your form has been submitted, and a team
                      member will review your request as soon
                      as possible.
                    </h4>
                  </div>
                </DialogContent>
              </div>
              
          )}
          {
            !success && (
              <div>
                <DialogTitle>Submission Failed</DialogTitle>
                <DialogContent>
                  <div>
                    <h4>
                      Something went wrong. Please try again later.
                    </h4>
                    {errorMsg}
                  </div>
                </DialogContent>
              </div>
          )}
      </Dialog>
    </div>
  );
};

export default RoleRequest;
