import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Select, MenuItem, SelectChangeEvent, OutlinedInput, InputLabel, Box } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"



const defaultFormData = {
    type: "Select One",
    location: "Select One",
    message: "",
};

const locations = [
    "Select One",
    'Log In',
    'Uploading Photo',
    'Making Observations',
    'Searching for a user',
    'Searching for a volcano',
    'Volcano map',
    'Reporting an account',
    'Reporting a post',
    'Verifying posts',
    'Other',
];

const types = [
    "Select One",
    'Feedback',
    'Bug Report',
    'Missing Volcano',
    'Other',
];

export default function FeedbackForm() {
    let navigate = useNavigate();

    const [formData, setFormData] = useState(defaultFormData);
    const { type, location, message } = formData;
    const [dialogHeading, setDialogHeading] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const handleSelect = (e: SelectChangeEvent) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogHeading('');
        setDialogMessage('');
    }

    const handleReturnToForm = () => {
        if (isSuccessful) {setFormData(defaultFormData)}
        handleCloseDialog()
    }

   
    let handleSubmit = async() => {
        if(formData.type === "Select One" && formData.location === "Select One" && formData.message === ""){
            setDialogHeading('Empty Form')
            setDialogMessage('Please fill out at least one field.')
            handleOpenDialog();
        }
        else{
            try {
                const res = await fetch(`/api/users/feedback/`, {
                    method: 'POST',
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        submitted_id: '0',
                        type: formData.type,
                        location: formData.location,
                        message: formData.message,
                    })
                });
                console.log("RESPONSE STATUS:" + res.status)         
                if (res.ok){
                    setDialogHeading('Feedback Recieved')
                    setDialogMessage('Thank you for providing your feedback.')
                    setIsSuccessful(true)
                } else {
                    setDialogHeading('Feedback Failed')
                    setDialogMessage('Something went wrong. Please return to the form and try to submit again.')
                }
            } catch (error) {
                setDialogHeading('Feedback Status')
                setDialogMessage(`Hmmm. It seems like you are not connected to the internet right now. 
                                Don't worry, you can go ahead and we will automatically re-send this 
                                when you are connected again. Thank you for providing your feedback.`)
                setIsSuccessful(true)
            } finally {
                handleOpenDialog();
            } 

        }

        
    };

    return (
        <Box>
            <form>
                <div>
                    <InputLabel id="location-label">Feedback Type</InputLabel>
                    <Select
                        labelId="location-select-label"
                        name="type"
                        value={type}
                        label="Feedback Type"
                        onChange={handleSelect}
                        input={<OutlinedInput label="Name" />}
                        sx={{
                            width: 200,
                            height: 50
                        }}
                    >
                        {types.map((type) => 
                        <MenuItem
                            key={type}
                            value={type}
                        >{type}</MenuItem>
                        )}
                    </Select>
                </div>
                <br />
                <div>
                    <InputLabel id="location-label">Page</InputLabel>
                    <Select
                        labelId="location-select-label"
                        name="location"
                        value={location}
                        label="Site Location"
                        onChange={handleSelect}
                        input={<OutlinedInput label="Name" />}
                        sx={{
                            width: 200,
                            height: 50
                        }}
                    >
                        {locations.map((location) => 
                        <MenuItem
                            key={location}
                            value={location}
                        >{location}</MenuItem>
                        )}
                    </Select>
                </div>
                <br />
                <label htmlFor="message">Comments</label>
                <br />
                <div>
                    <TextField 
                        id="message"
                        multiline
                        rows={4}
                        fullWidth
                        value={message} onChange={handleChange}>
                    </TextField>
                </div>
                <br />
                <Box
                    m={1}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button color="secondary" onClick={() => navigate(-1)} sx={{ m: 3 }}>Back</Button> 
                    <Button onClick={handleSubmit} sx={{ m: 3 }}>Submit</Button>  
                </Box>
                
            </form>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogHeading}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleReturnToForm} sx={{ m: 3}}>Return to Form</Button>
                    <Button onClick={() => navigate(-1)} sx={{ m: 3}}>Resume</Button>
                </DialogActions>
            </Dialog>
        </Box>  
    )
}