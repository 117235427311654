import * as React from "react";
import { Button, TextField } from "@mui/material";
import { BackButtonHeader } from "./BackButtonHeader";

export function CommentInput({
  onSubmit,
  onBack,
  submitting,
  heading,
  label,
}: {
  onSubmit: (comment: string) => void;
  onBack: () => void;
  submitting: boolean;
  heading: string;
  label: string;
}) {
  const [value, setValue] = React.useState("");
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <BackButtonHeader
        title={heading}
        onBack={onBack}
      />
      <TextField
        label="Anything else you'd like to share"
        onChange={(e) => setValue(e.target.value)}
        disabled={submitting}
        multiline
        rows={5} 
        //rows makes it a set size and add a scrolling bar if reach limit. Without rows, it expand base on size of text.
      />
      <Button onClick={() => onSubmit(value)} disabled={submitting} sx={{ marginTop: "20px"}}>
        {submitting ? "Submitting..." : label}
      </Button>
    </div>
  );
}