import { Tab, Tabs } from "@mui/material";
import * as React from "react";
import UserSearch from "../components/UserSearch";
import { VolcanoSearch } from "./VolcanoSearch";

// TODO: integrate user search tab
export function Search() {
  const [selected, setSelected] = React.useState<0 | 1>(0);
  return (
    <main>
      <Tabs
        centered
        value={selected}
        onChange={(_event, newTab: 0 | 1) => setSelected(newTab)}
      >
        <Tab label="Volcano search" />
        <Tab label="User search" />
      </Tabs>
      <div
       style={{ marginTop: "40px" }}>
        {selected === 0 ? <VolcanoSearch /> : <UserSearch />}
      </div>
    </main>
  );
}
