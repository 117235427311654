import * as React from "react";
import { Button, Divider } from "@mui/material";
import { Link } from "../components/Link";

function ReviewReports() {
  return (
    <div
    /*
    style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }}
    */
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "center",
      rowGap: "20px",
    }}
      >
     
        <Link to="/viewreportpost">
          <Button fullWidth sx={{ mt: 3, mb: 2 }}>
            Review Reported Posts
          </Button>
        </Link>

        <div>
          <Divider>OR</Divider>
        </div>

        <Link to="/viewreportuser">
          <Button fullWidth sx={{ mt: 3, mb: 2 }}>
            Review Reported Users
          </Button>
        </Link>
    </div>
  );
}
export default ReviewReports;
