import * as React from 'react'
import { Report, defaultReport } from '../Report';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton } from "@mui/material";
import Popup from '../components/Popup';
import ReportInfoInputUser from '../components/ReportInfoInputUser';


const ReportUser = () => {

  const [msg, setMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState('');
  const navigate = useNavigate();
  const { uid: reportedUid } = useParams();
  const user_id = localStorage.getItem('userID');
  const [reportInfo, setReportInfo] = useState<Report>({
    ...defaultReport(),
    reported_user: Number(reportedUid),
    report_type: "user",
    reporting_user: Number(user_id),
    report_status: "reported"
  });

  const togglePopup = () => {
      setIsOpen(!isOpen);
    };

  let sendReport = async () => {
    console.log('user_id', user_id);
    console.log('reported_userid', reportedUid);
    if(!reportInfo.content && !reportInfo.spam && !reportInfo.plagiarism 
      && !reportInfo.ai_generated && !reportInfo.stock_photo 
      && !reportInfo.other && reportInfo.report_comments === ""){
        setMsg("Please select report options or enter comments to submit a report.");
    }else{
      console.log(reportInfo)
      fetch(`/api/reports/submitReport/`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body : JSON.stringify(reportInfo)
      })
      .then((response) => {
        if(response.ok){
          return response.json()
        }
        else{
          throw response
        }
      })
      setMsg("Your report has been sent to our moderation team.");
      setTimeout(() => navigate(-1), 3000);
    }
      
    setSubmitted(true);
    setIsOpen(true);


  }



  

  return (
    <div style = {{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {submitted && (
            <Popup
                    open={isOpen}
                    onClose={togglePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{
                        textAlign: 'center', 
                        padding: '20px',
                        margin: 'auto',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                    content={
                      <>
                        {msg}
                      </>
                    }
                    />
          )
      }
      <div style = {{display: 'flex', flexDirection: 'row'}}>
        <IconButton
            onClick={() => {
              navigate(-1)
            }}>
            <ArrowBack/>
          </IconButton>
        <h2>Report this User:</h2>
      </div>
      
      
      <ReportInfoInputUser 
      reportInfo = {reportInfo}
      setReportInfo = {setReportInfo} 
      reason = {reason}
      setReason = {setReason}
      />

      <Button
      onClick = {() => sendReport()}
      style={{marginTop: '5vh', width: '25vh'}}
      >Submit Report</Button>
      
    </div>
  );
}

export default ReportUser;