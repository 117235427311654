import * as React from "react";
import {
  WipObservation,
  Sights,
  SightState,
  SMELL_DISPLAY,
  Smells,
  SOUND_DISPLAY,
  Sounds,
  SIGHT_DISPLAY,
} from "../Observation";
import lavaFountain from "../images/lava_fountain.png";
import aALavaFlow from "../images/a_a_lava_flow.png";
import slabbyPahoehoeFlow from "../images/slabby_pahoehoe_flow.png";
import pelesHair from "../images/peles_hair.png";
import laze from "../images/laze.jpg";
import volcanicPlume from "../images/volcanic_plume.jpg";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { SizedFixedImage } from "./SizedImage";

function MultiCheckboxInput<Boxes extends Record<string, boolean>>({
  title,
  initBoxes,
  display,
  onSubmit,
  onSkip,
  onBack,
}: {
  title: string;
  initBoxes: Boxes;
  display: Record<keyof Boxes, string>;
  onSubmit: (boxes: Boxes, comment: string) => void;
  onSkip: () => void;
  onBack: () => void;
}) {
  const [boxes, setBoxes] = React.useState(initBoxes);
  const [comment, setComment] = React.useState("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <IconButton onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <h2>{title}</h2>
        <div />
      </div>

      <FormGroup>
        {Object.entries(boxes)
          .filter(([key, _]) => key !== "skip")
          .map(([key, checked]) => {
            const update = () => {
              setBoxes({
                ...boxes,
                [key]: !checked,
              });
            };

            return (
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={update} />}
                label={display[key as keyof Sounds]}
                key={key}
              />
            );
          })}
      </FormGroup>
      <TextField
        label="Other"
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={() => onSkip()}>Skip</Button>
        <Button onClick={() => onSubmit(boxes, comment)}>Next</Button>
      </div>
    </div>
  );
}

export function SoundsInput({
  initSounds,
  onSubmit,
  onBack,
}: {
  initSounds: Sounds;
  onSubmit: (sounds: Sounds, comment: string) => void;
  onBack: () => void;
}) {
  return (
    <MultiCheckboxInput
      title="Did You Hear It?"
      initBoxes={initSounds}
      display={SOUND_DISPLAY}
      onSubmit={onSubmit}
      onBack={onBack}
      onSkip={() => {
        onSubmit(
          {
            rumble: false,
            roar: false,
            shatter: false,
            hiss: false,
            explosion: false,
            burst: false,
            silence: false,
            skip: true,
          },
          ""
        );
      }}
    />
  );
}

export function SmellsInput({
  initSmells,
  onSubmit,
  onBack,
}: {
  initSmells: Smells;
  onSubmit: (smells: Smells, comment: string) => void;
  onBack: () => void;
}) {
  return (
    <MultiCheckboxInput
      title="Did You Smell It?"
      initBoxes={initSmells}
      display={SMELL_DISPLAY}
      onSubmit={onSubmit}
      onBack={onBack}
      onSkip={() => {
        onSubmit(
          {
            rottenEggs: false,
            gunPowder: false,
            fireworks: false,
            campfire: false,
            nothing: false,
            skip: true,
          },
          ""
        );
      }}
    />
  );
}

const SIGHT_IMAGES: ReadonlyArray<[string, keyof Sights]> = [
  [lavaFountain, "lavaFountain"],
  [aALavaFlow, "aALavaFlow"],
  [slabbyPahoehoeFlow, "slabbyPahoehoeFlow"],
  [pelesHair, "pelesHair"],
  [laze, "laze"],
  [volcanicPlume, "volcanicPlume"],
];

export function SightsInput({
  observation,
  setObservation,
  onSubmit,
  onBack,
  fromEnd,
}: {
  observation: WipObservation;
  setObservation: (o: WipObservation) => void;
  onSubmit: () => void;
  onBack: () => void;
  fromEnd: boolean;
}) {
  const [page, setPage] = React.useState(fromEnd ? SIGHT_IMAGES.length - 1 : 0);

  const next = () => {
    if (page === SIGHT_IMAGES.length - 1) {
      onSubmit();
    } else {
      setPage((page) => page + 1);
    }
  };

  const back = () => {
    if (page === 0) {
      onBack();
    } else {
      setPage((page) => page - 1);
    }
  };

  const [sightImg, sightKey] = SIGHT_IMAGES[page]!;

  const update = (to: SightState) => {
    setObservation({
      ...observation,
      sights: { ...observation.sights, [sightKey]: to },
    });
    next();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: "20px"
        }}
      >
        <IconButton onClick={back}>
          <ArrowBack />
        </IconButton>
        <h2>Did You See It?</h2>
        <span>
          {page + 1}/{SIGHT_IMAGES.length}
        </span>
      </Box>

      <SizedFixedImage src={String(sightImg)} alt={sightKey} />
      <h3>{SIGHT_DISPLAY[sightKey]}</h3>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          width: "min-content",
        }}
      >
        <Button onClick={() => update("no")}>No</Button>
        <Button onClick={() => update("yes")}>Yes</Button>
        <Button onClick={() => update("unsure")}>Not sure</Button>
        <Button onClick={() => update("skip")}>Skip</Button>
      </Box>
    </Box>
  );
}
