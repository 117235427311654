import React from "react";
import { Box } from "@mui/material";
import { Link } from "./Link";

export default function GeolocationError() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        maxWidth: "sm",
        gap: "10px",
        padding: "20px",
      }}
    >
      <span style={{ fontWeight: "bold" }}>Location Error</span>
      Did You See It doesn't have permission to use the location of your device.
      To get the most out of this app please allow location services on this
      device.
      <Link style={{ fontWeight: 500 }} to="/about/#how-to-allow-location">
        How to turn on location services
      </Link>
      <Link style={{ fontWeight: 500 }} to="/about/#how-location-info-is-used">
        How we use location data
      </Link>
    </Box>
  );
}
