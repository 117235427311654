import * as React from "react";
import type { Post } from "../Post";
import { CircularProgress, Link as MuiLink } from "@mui/material";
import { PostView } from "./PostView";
import { ErrorIndicator } from "./ErrorPage";
import { Link } from "./Link";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { LogoIcon } from "../images/LogoIcon";

export type Review =
  | {
      status: "accepted";
      message: string;
    }
  | {
      status: "rejected";
      message: string;
    };

export type ReviewState = {
  step: "finishing-review";
  posts: Array<Post>;
  page: number;
  review: Review;
  submitting: boolean;
};

type SelectedState = {
  step: "selected";
  posts: Array<Post>;
  page: number;
};

export type PostGalleryState =
  | {
      step: "fetching";
    }
  | {
      step: "list";
      posts: Array<Post>;
      page: number;
    }
  | SelectedState
  | ReviewState
  | {
      step: "error";
      error: unknown;
    };

type PassdownStateHandler<Specialized> = React.Dispatch<
  PostGalleryState | ((state: Specialized) => PostGalleryState)
>;

export function PostGallery({
  posts,
  emptyListPlaceholder,
  postPreviewTitle: initPreviewTitle,
  postPreviewSubtitle: initPreviewSubtitle,
  postViewOverlay,
  reviewHandler,
  seeMoreText,
  galleryTitle,
  onMoved,
}: {
  posts: Post[] | Promise<Post[]>;
  emptyListPlaceholder?: JSX.Element;
  postPreviewTitle?: (post: Post) => string;
  postPreviewSubtitle?: (post: Post) => string;
  postViewOverlay?: (
    setState: PassdownStateHandler<SelectedState>
  ) => JSX.Element;
  reviewHandler?: (
    state: ReviewState,
    setState: PassdownStateHandler<ReviewState>
  ) => JSX.Element;
  seeMoreText?: string;
  galleryTitle: string | JSX.Element;
  onMoved?: (post: Post) => void;
}): JSX.Element {
  const [state, setState] = React.useState<PostGalleryState>({
    step: "fetching",
  });
  const postPreviewTitle = initPreviewTitle ?? defaultPostPreviewTitle;
  const postPreviewSubtitle = initPreviewSubtitle ?? defaultPostPreviewSubtitle;

  React.useEffect(() => {
    setState({ step: "fetching" });
    Promise.resolve(posts)
      .then(async (posts) => {
        setState({ step: "list", posts, page: 0 });
      })
      .catch((error) => {
        setState({ step: "error", error });
        console.error("Error fetching user posts: ", error);
      });
  }, [posts]);

  switch (state.step) {
    case "fetching":
      return (
        <div
          style={{
            flex: "1 1 100%",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    case "list":
      if (state.posts.length === 0) {
        return (
          emptyListPlaceholder ?? (
            <ErrorIndicator
              icon={
                <LogoIcon
                  className="logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              }
              header={"We couldn't find any posts here."}
              body={
                <>
                  Try <Link to="/">going home</Link> to find some brand new
                  posts.
                </>
              }
            />
          )
        );
      }

      return (
        <>
          {typeof galleryTitle === "string" ? (
            <h1 style={{ textAlign: "center" }}>{galleryTitle}</h1>
          ) : (
            galleryTitle
          )}
          <Splide
            style={{
              padding: "0 2.5em 40px",
              width: "min(100%, 850px)",
            }}
            options={{
              lazyLoad: "nearby",
              start: state.page,
              autoHeight: true,
              perPage: 1,
              classes: {
                pagination: "splide__pagination",
              },
            }}
            onMoved={
              onMoved !== undefined
                ? (_, index) => onMoved(state.posts[index]!)
                : undefined
            }
          >
            {state.posts.map((post, page) => {
              const select = () =>
                setState({
                  step: "selected",
                  posts: state.posts,
                  page,
                });
              return (
                <SplideSlide key={post.id}>
                  <div
                    style={{
                      flex: "1 1 calc(min(500px, 100vw))",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      style={{
                        flex: "1 0 calc(min(400px, 100vw - 100px))",
                        height: "1px",
                        maxWidth: "100%",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={select}
                      src={String(post.observation.photo)}
                      alt=""
                      data-splide-lazy={String(post.observation.photo)}
                    />
                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        textAlign: "center",
                        rowGap: "5px",
                      }}
                    >
                      <span>{postPreviewTitle(post)}</span>
                      <span>{postPreviewSubtitle(post)}</span>
                      <span>
                        <MuiLink
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            cursor: "pointer",
                          }}
                          onClick={select}
                        >
                          {seeMoreText ?? "See more"}
                        </MuiLink>
                      </span>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </>
      );
    case "selected":
      const overlaySetState: PassdownStateHandler<SelectedState> = (
        newState:
          | PostGalleryState
          | ((state: SelectedState) => PostGalleryState)
      ) => {
        if (typeof newState === "function") {
          setState(newState(state));
        } else {
          setState(newState);
        }
      };
      return (
        <PostView
          post={state.posts[state.page]!}
          onBack={() => setState({ ...state, step: "list" })}
          overlay={
            postViewOverlay !== undefined
              ? postViewOverlay(overlaySetState)
              : undefined
          }
        />
      );

    case "error":
      return <ErrorIndicator />;

    case "finishing-review":
      const reviewSetState: PassdownStateHandler<ReviewState> = (
        newState: PostGalleryState | ((state: ReviewState) => PostGalleryState)
      ) => {
        if (typeof newState === "function") {
          setState(newState(state));
        } else {
          setState(newState);
        }
      };
      return reviewHandler!(state, reviewSetState);
  }
}

function defaultPostPreviewTitle(post: Post) {
  return post.volcano.name;
}

function defaultPostPreviewSubtitle(post: Post) {
  return post.observation.datetime.toLocaleString();
}
