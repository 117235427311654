import { DateTime } from "luxon";
import type { Location } from "./Post";
import type { RawVolcano } from "./Volcano";

export type SightState = "no" | "yes" | "unsure" | "skip";

export type Sounds = {
  rumble: boolean;
  roar: boolean;
  shatter: boolean;
  hiss: boolean;
  explosion: boolean;
  burst: boolean;
  silence: boolean;
  skip: boolean;
};

export type Smells = {
  rottenEggs: boolean;
  gunPowder: boolean;
  fireworks: boolean;
  campfire: boolean;
  nothing: boolean;
  skip: boolean;
};

export type Sights = {
  lavaFountain: SightState;
  aALavaFlow: SightState;
  slabbyPahoehoeFlow: SightState;
  pelesHair: SightState;
  laze: SightState;
  volcanicPlume: SightState;
};

export type OtherObservation = {
  sounds: string;
  smells: string;
  additional: string;
};

export type WipObservation = {
  photo: URL;
  location: Location | undefined;
  volcano: number;
  datetime: DateTime;
  time_unknown: boolean;
  sounds: Sounds;
  smells: Smells;
  sights: Sights;
  other: OtherObservation;
};

export type Observation = WipObservation & { id: string };

export function defaultObservation(
  photo: URL,
  location: Location | undefined,
  volcano: number,
  datetime: DateTime,
  time_unknown: boolean
): WipObservation {
  return {
    photo,
    location,
    volcano,
    datetime,
    time_unknown,
    sounds: {
      rumble: false,
      roar: false,
      shatter: false,
      hiss: false,
      explosion: false,
      burst: false,
      silence: false,
      skip: false,
    },
    smells: {
      rottenEggs: false,
      gunPowder: false,
      fireworks: false,
      campfire: false,
      nothing: false,
      skip: false,
    },
    sights: {
      lavaFountain: "skip",
      aALavaFlow: "skip",
      slabbyPahoehoeFlow: "skip",
      pelesHair: "skip",
      laze: "skip",
      volcanicPlume: "skip",
    },
    other: {
      sounds: "",
      smells: "",
      additional: "",
    },
  };
}

export type RawObservation = RawWipObservation & {
  observation_id: string;
};

export type RawFullObservation = Omit<RawObservation, "volcano_number"> & {
  volcano_number: RawVolcano;
};

type RawWipObservation = {
  photo_datetime: string;
  photo: string;
  longitude: string | number | null;
  latitude: string | number | null;
  volcano_number: string | number;
  extra_sound_comments: string;
  extra_smell_comments: string;
  extra_comments: string;
  campfire: boolean;
  fireworks: boolean;
  gun_powder: boolean;
  rotten_eggs: boolean;
  no_smell: boolean;
  skip_smells: boolean;
  hiss: boolean;
  shatter: boolean;
  roar: boolean;
  rumble: boolean;
  burst: boolean;
  explosion: boolean;
  silence: boolean;
  skip_sounds: boolean;
  slabby_flow: SightState;
  lava_flow: SightState;
  lava_fountain: SightState;
  volcanic_plume: SightState;
  laze: SightState;
  peles_hair: SightState;
  time_unknown: boolean;
};

const NUM_DECIMAL_PLACES = 6;

export function makeRawWipObservation(observation: WipObservation): unknown {
  const flat: RawWipObservation = {
    photo_datetime: observation.datetime.toISO(),
    photo: String(observation.photo),
    longitude:
      (observation.location &&
        Math.round(observation.location.long * 10 ** NUM_DECIMAL_PLACES) /
          10 ** NUM_DECIMAL_PLACES) ??
      null,
    latitude:
      (observation.location &&
        Math.round(observation.location.lat * 10 ** NUM_DECIMAL_PLACES) /
          10 ** NUM_DECIMAL_PLACES) ??
      null,
    volcano_number: observation.volcano,
    extra_sound_comments: observation.other.sounds,
    extra_smell_comments: observation.other.smells,
    extra_comments: observation.other.additional,
    campfire: observation.smells.campfire,
    fireworks: observation.smells.fireworks,
    gun_powder: observation.smells.gunPowder,
    rotten_eggs: observation.smells.rottenEggs,
    no_smell: observation.smells.nothing,
    skip_smells: observation.smells.skip,
    hiss: observation.sounds.hiss,
    shatter: observation.sounds.shatter,
    roar: observation.sounds.roar,
    rumble: observation.sounds.rumble,
    burst: observation.sounds.burst,
    explosion: observation.sounds.explosion,
    silence: observation.sounds.silence,
    skip_sounds: observation.sounds.skip,
    slabby_flow: observation.sights.slabbyPahoehoeFlow,
    lava_flow: observation.sights.aALavaFlow,
    lava_fountain: observation.sights.lavaFountain,
    laze: observation.sights.laze,
    peles_hair: observation.sights.pelesHair,
    volcanic_plume: observation.sights.volcanicPlume,
    time_unknown: observation.time_unknown
  };

  return flat;
}

export function fromRawObservation(untyped: unknown): Observation {
  const raw = untyped as RawObservation;

  return {
    id: raw.observation_id,
    volcano: Number(raw.volcano_number),
    photo: new URL(raw.photo),
    datetime: DateTime.fromISO(raw.photo_datetime, { setZone: true }),
    time_unknown: raw.time_unknown,
    location:
      raw.latitude != null && raw.longitude != null
        ? {
            lat: Number(raw.latitude),
            long: Number(raw.longitude),
          }
        : undefined,
    smells: {
      campfire: raw.campfire,
      fireworks: raw.fireworks,
      gunPowder: raw.gun_powder,
      rottenEggs: raw.rotten_eggs,
      nothing: raw.no_smell,
      skip: raw.skip_smells,
    },
    sounds: {
      hiss: raw.hiss,
      shatter: raw.shatter,
      roar: raw.roar,
      rumble: raw.rumble,
      burst: raw.burst,
      explosion: raw.explosion,
      silence: raw.silence,
      skip: raw.skip_sounds,
    },
    sights: {
      lavaFountain: raw.lava_fountain,
      aALavaFlow: raw.lava_flow,
      slabbyPahoehoeFlow: raw.slabby_flow,
      pelesHair: raw.peles_hair,
      laze: raw.laze,
      volcanicPlume: raw.volcanic_plume,
    },
    other: {
      sounds: raw.extra_sound_comments,
      smells: raw.extra_smell_comments,
      additional: raw.extra_comments,
    },
  };
}

export const SIGHT_DISPLAY: { [key in keyof Sights]: string } = {
  lavaFountain: "Lava fountain",
  aALavaFlow: "'A'ā lava flow",
  slabbyPahoehoeFlow: "Slabby pahoehoe flow",
  pelesHair: "Pele's hair",
  laze: "Laze",
  volcanicPlume: "Volcanic plume",
};

export const SOUND_DISPLAY: { [key in keyof Sounds]: string } = {
  rumble: "Rumble",
  roar: "Roar",
  shatter: "Shatter",
  hiss: "Hiss",
  explosion: "Explosion",
  burst: "Burst",
  silence: "Silence",
  skip: "Skipped",
};

export const SMELL_DISPLAY: { [key in keyof Smells]: string } = {
  rottenEggs: "Rotten eggs",
  gunPowder: "Gun powder",
  fireworks: "Fireworks",
  campfire: "Campfire",
  nothing: "Nothing",
  skip: "Skipped",
};

export function describeObservation(observation: Observation): string {
  const sights = Object.entries(observation.sights)
    .filter(([_, state]) => state === "yes")
    .map(([name, _]) => SIGHT_DISPLAY[name as keyof Sights]);

  if (sights.length > 0) {
    return sights.join(", ");
  }

  const sounds = Object.entries(observation.sounds)
    .filter(([_, state]) => state)
    .map(([name, _]) => SOUND_DISPLAY[name as keyof Sounds]);

  if (sounds.length > 0) {
    return sounds.join(", ");
  }

  const smells = Object.entries(observation.smells)
    .filter(([_, state]) => state)
    .map(([name, _]) => SMELL_DISPLAY[name as keyof Smells]);

  if (smells.length > 0) {
    return smells.join(", ");
  }

  return "";
}