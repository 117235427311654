import { Button } from '@mui/material'
import * as React from 'react'
import {useState} from 'react'
import { useParams } from 'react-router-dom'

type resetPassInfo = {
    uid: string | undefined;
    token: string | undefined;
    new_password: string;
    re_new_password: string;
}

const ConfirmPassReset = () => {

    let {uid, token} = useParams()
    const [info, setInfo] = useState<resetPassInfo>({
        uid:uid, 
        token:token,
        new_password: "",
        re_new_password: ""})
    const [stateMsg, setStateMsg] = useState("")

    let submitPassChange = async () => {
        console.log('info', info)
        fetch(`/auth/users/reset_password_confirm/`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(info)
        }).then((response) => {
            if(response.status === 400){
                setStateMsg("An error has occurred.")
                throw response
            }
            else {
                setStateMsg("Password successfully reset. Please proceed to login.")
                return response.json()
            }
        })
    }
    




  return (
    <div>
        <h2>Reset your password:</h2>
        {stateMsg}
        <input
        type="password"
        placeholder="New Password"
        onChange = {(e) => {
            setInfo({...info, new_password : e.target.value})
        }}
        />
        <input
        type="password"
        placeholder="Confirm New Password"
        onChange = {(e) => {
            setInfo({...info, re_new_password : e.target.value})
        }}
        />
        <Button
        onClick = {() => submitPassChange()}>
            Change Password
        </Button>

    </div>
  )
}

export default ConfirmPassReset