import * as React from "react";
import accept from "../images/Accept.png";
import reject from "../images/Reject.png";
import IconButton from "@mui/material/IconButton";



export function AcceptDeny({ setState }: { setState: (state: boolean) => void }) {
    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
        }}>
            <IconButton onClick={() => setState(false)}>
            <img className="reject" src={reject} alt="reject"
            style = {{
                width: '8vh', 
                height: '8vh', 
                marginRight: '2vh'
                }} 
            />
                </IconButton>
            <IconButton onClick={() => setState(true)}>
            <img className="accept" src={accept} alt="accept"
            style = {{
                width: '8vh', 
                height: '8vh', 
                marginRight: '2vh'
                }} 
            />
            
                </IconButton>
        </div>
    );
}