import * as React from 'react';
import { Avatar, CssBaseline, TextField, Grid, Box, Typography, Container, Button, InputAdornment, IconButton, Dialog} from '@mui/material'
import { Link } from "../components/Link";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CircularProgress } from "@mui/material";
import Popup from '../components/Popup';
import { useLocation } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import { LogoIcon } from '../images/LogoIcon';
//import PersonalPosts from '../components/PersonalPosts';

type State =
  | {
    step: "login";
  }
  | {
    step: "loading";
  };

function Login () {

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShow] = React.useState(false);
  const [state, setState] = React.useState<State>({ step: "login" });
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogOpen, setDialogueOpen] = React.useState(true);
  const [suspendedDialog, setSuspendedDialog] = React.useState(false);
  const [isSuspended, setIsSuspended] = React.useState(false);

  const location = useLocation();
  let msg = location.state?.msg;
  let fromActivate = location.state?.fromActivate;

//Handle close, if change in popup to props, make instant popup for this
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleClickShowPassword = () => {
    setShow(!showPassword)
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleCloseDialog = () => {
    setDialogueOpen(false);
    fromActivate = false;
}
/*
  React.useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace('/'); //switch page
    } 
  }, []);
*/

  const onSubmit = (e:any) => {
    e.preventDefault();
    console.log("Before Email", email)
    const user = {
      email: email,
      password: password
    };
    console.log("After Email", email)

    fetch('/api/users/token-auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(json => {
        if (json.access) {
          setIsOpen(false)
          setState({step: 'loading'});
          localStorage.clear();
          localStorage.setItem('refreshToken', json.refresh);
          localStorage.setItem('accessToken', json.access);
          localStorage.setItem('token', json.access)
          fetch('/api/users/current/', {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          }).then((res) => res.json()).then((json) => {
            localStorage.setItem('userID',json.user_id);
            if(json.role === "suspended"){
              setIsSuspended(true);
              setSuspendedDialog(true);
              setState({step: 'login'});
            }
            else {
              localStorage.setItem("currentRole", json.role);
              localStorage.setItem("username", json.username);
              window.location.replace('/'); //Change use react redom navigate
            }
          });
        } else {
          setIsOpen(true);
          localStorage.clear();
        }
      });
      
  };

  switch (state.step) {
    case "login":
    return (
      <div>
        <div>
          {
            fromActivate && (
              <>
              <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogContent>
                        <h3>{msg}</h3>
                    </DialogContent>
              </Dialog>

              </>

              
            )

          }
        </div>
        <div>
          {
            isSuspended && (
              <Popup
              title= "Suspended Account"
              open={suspendedDialog}
              onClose = {() => setSuspendedDialog(false)}
              content={
                <>
                  <p>
                    Your account has been suspended from using Did You See It.
                  </p>
                </>
              }

              />
            )
          }
        </div>
        <Popup
        title = "Incorrect Credentials"
        open = {isOpen}
        onClose={togglePopup}
        content={<>
                <p>
                  The information entered is incorrect. Please enter the correct email and password.
                </p>
                </>}
        />
       <Container component="main" maxWidth="xs">
         <CssBaseline />
         <Box
           sx={{
             marginTop: 8,
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
           }}
         > {/*Profile symbol*/}
           <Avatar sx={{ m: 1, bgcolor: 'darkred' }}>
             <LogoIcon />
           </Avatar>
           <Typography component="h1" variant="h5">
             Sign in
           </Typography>
           {/*Email/Username box*/}
           <Box component="form"  noValidate sx={{ mt: 1 }}>
             <TextField
               label='Email'
               margin="normal"
               required
               fullWidth
               type="normal"
               onChange={e => setEmail(e.target.value.toLowerCase())} 
               //The .toLowerCase() make the email lower case no matter what. Making it 'case-insensitive'
               //inputProps={{ maxLength: 32}}
               autoComplete="Email"
               autoFocus
             />
             {/*Password box*/}
             <TextField
              label='Password'
              margin="normal"
              required
              fullWidth
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment :
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }} 
          />
              <Button
               type="submit"
               onClick={onSubmit}
               fullWidth
               sx={{ mt: 3, mb: 2 }}
             >
               Sign In
             </Button>
             <Grid container>
               {/* Sign up option*/}
               <Grid item>
                 <Link sx={{ color:'darkred' }} to="/register">
                   {"Don't have an account? Sign Up"}
                 </Link>
               </Grid>
             </Grid>
             <Grid container>
               {/* Reset Password*/}
               <Grid item>
                 <Link sx={{ color:'darkred' }} to="/submit-reset">
                   {"Forgot your password? Reset"}
                 </Link>
               </Grid>
             </Grid>
           </Box>
         </Box>
       </Container>
     </div>
    )
    case "loading":
      return <CircularProgress/>
  }
}
export default Login;