import * as React from "react";
import { Avatar } from "@mui/material";
import { comparePosts, fromRawPost, Post } from "../Post";
import { CircularProgress } from "@mui/material";
import { UserMenu } from "../components/UserMenu";
import { useParams } from "react-router-dom";
import { ErrorIndicator } from "../components/ErrorPage";
import { LogoIcon } from "../images/LogoIcon";
import { PostGallery } from "../components/PostGallery";

function ProfileDetail() {
  const uid = useParams()["uid"]!;
  const [username, setUsername] = React.useState<string>();
  const [focusedPost, setFocusedPost] = React.useState<string>();
  const [posts] = React.useState<Promise<Post[]>>(() =>
    fetch(`/api/posts/userposts/${uid}`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            `Error fetching user's posts: ${res.status} ${res.statusText}`
          );
        }
      })
      .then(async (rawPosts: Array<unknown>) => {
        const posts = await Promise.all(rawPosts.map(fromRawPost));
        posts.sort(comparePosts);
        if (posts[0] !== undefined) {
          setFocusedPost(posts[0].id);
        }
        return posts;
      })
  );

  React.useEffect(() => {
    console.log(uid);
    fetch(`/api/users/${uid}/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setUsername(json.username);
      });
  }, [uid]);

  if (username === undefined) {
    return <CircularProgress sx={{ marginTop: "50%" }} />;
  }

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 60px)",
        width: "min(100%, 850px)",
        padding: "0 20px 20px",
        justifyContent: "stretch",
        alignItems: "center",
      }}
    >
      <h1>{username}'s Profile</h1>
      <Avatar sx={{ m: 1, bgcolor: "darkred" }}>
        <LogoIcon />
      </Avatar>

      <UserMenu username={username} userid={uid} postid={focusedPost} />
      <PostGallery
        posts={posts}
        galleryTitle={
          <h2 style={{ textAlign: "center" }}>{username}'s Posts</h2>
        }
        emptyListPlaceholder={
          <ErrorIndicator
            icon={<LogoIcon style={{ width: "100%", height: "100%" }} />}
            header={`${username} hasn't made any posts yet.`}
            body=""
          />
        }
        onMoved={(post) => setFocusedPost(post.id)}
      />
    </main>
  );
}
export default ProfileDetail;
