import * as React from 'react'
import { Report, defaultReport } from '../Report';
import ReportInfoInput from '../components/ReportInfoInput';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton } from "@mui/material";
import Popup from '../components/Popup';

const ReportPost = () => {
    const [msg, setMsg] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [reason, setReason] = useState('');
    const navigate = useNavigate()
    const { pid } = useParams()
    const user_id = localStorage.getItem('userID')

    const [reportInfo, setReportInfo] = useState<Report>({
      ...defaultReport(),
      reported_post: Number(pid),
      report_type: "post",
      reporting_user: Number(user_id),
      report_status: "reported",
    });

    const togglePopup = () => {
      setIsOpen(!isOpen);
    };

    let sendReport = async () => {
      console.log(reason);
      if(!reportInfo.content && !reportInfo.spam && !reportInfo.plagiarism 
        && !reportInfo.ai_generated && !reportInfo.stock_photo
        && !reportInfo.other && reportInfo.report_comments === ""){
          setMsg("Please select report options or enter comments to submit a report.");
          console.log("here")
      }else{
        fetch(`/api/reports/submitReport/`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body : JSON.stringify(reportInfo)
        })
        .then((response) => {
          if(response.ok){
            return response.json()
          }
          else{
            throw response
          }
        })
        setMsg("Your report has been sent to our moderation team. Redirecting...");
        setTimeout(() => navigate(-1), 3000);
      }

      setSubmitted(true);
      setIsOpen(true);
    }

  return (

    <div style = {{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {submitted && (
            <Popup
                    open={isOpen}
                    onClose={togglePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{
                        textAlign: 'center', 
                        padding: '20px',
                        margin: 'auto',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                    content={
                      <>
                        {msg}
                      </>
                    }
                    />
          )
      }
      <div style = {{display: 'flex', flexDirection: 'row'}}>
        <IconButton
            onClick={() => {
              navigate(-1)
            }}>
            <ArrowBack/>
          </IconButton>
        <h2>Report this Post:</h2>
      </div>
      
      
      <ReportInfoInput 
      reportInfo = {reportInfo}
      setReportInfo = {setReportInfo} 
      reason = {reason}
      setReason = {setReason}
      />

      <Button
      onClick = {() => sendReport()}
      style={{marginTop: '5vh', width: '25vh'}}
      >Submit Report</Button>
      
    </div>
  );
};

export default ReportPost;