import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import * as React from 'react'
import {useEffect} from 'react';
import {NavigateFunction, useNavigate, useParams} from 'react-router-dom';

const Activate = () => {
    const navigate = useNavigate();
    let {uid, token} = useParams();
    
    useEffect(() => {
        handleActivation(uid!, token!, navigate);
    }, [uid, token, navigate]);
    

    return (
        <CircularProgress />
    );
}

export default Activate;



async function verify(uid: string, token: string) {
    return fetch(`/auth/users/activation/`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ uid, token })
    });
}

async function handleActivation(uid: string, token: string, navigate: NavigateFunction) {
    try {
        const response = await verify(uid, token);
        if (response.ok) {
            const message = 'Your account has been activated, and you can proceed with login. Welcome to the Did You See It team!';
            navigate('/login', { state: { msg: message, verify: true, fromActivate: true } });
        } else {
            const errorMessage = `Activation link has encountered a timeout. Error: ${response.statusText}. Perhaps you need to resend the activation email, 
            or your account is already active.`;
            navigate('/login', { state: { msg: errorMessage, verify: false, fromActivate: true } });
            console.error("Error activating user: ", response.statusText);
        }
    } catch (error) {
        const errorMessage = `Something went wrong processing your activation.\n${error}`;
        navigate('/login', { state: { msg: errorMessage, verify: false, fromActivate: true } });
        console.error("Error activating user: ", error);
    }
}