import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  SightsInput,
  SoundsInput,
  SmellsInput,
} from "../components/ObservationInput";
import { CommentInput } from "../components/CommentInput";
import { makeRawWipObservation, WipObservation } from "../Observation";

const PHOTO_BASE_URL = "https://didyouseeitphotos.s3.amazonaws.com/media";

export function PostObservation({
  observation: initObservation,
  onBack,
}: {
  observation: WipObservation;
  onBack: () => void;
}) {
  const [observation, setObservation] =
    React.useState<WipObservation>(initObservation);
  const [page, setPage] = React.useState(0);
  const [sightsFromEnd, setSightsFromEnd] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();

  async function uploadObservation(observation: WipObservation) {
    setSubmitting(true);
    try {
      const [_, mime, data] = /^data:(image\/[^;]+);base64,(.+)$/.exec(
        String(observation.photo)
      )!;
      const photoId = await fetch("/api/posts/photo/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ image: { mime, data } }),
      }).then((resp) => {
        if (resp.ok) {
          return resp.text();
        } else {
          throw new Error(
            `Error uploading photo: ${resp.status} ${resp.statusText}`
          );
        }
      });

      const photo = new URL(`${PHOTO_BASE_URL}/${photoId}`);

      const body = JSON.stringify(
        makeRawWipObservation({ ...observation, photo })
      );
      const resp = await fetch("/api/posts/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body,
      });

      if (!resp.ok) {
        throw new Error(
          `Error creating post: ${resp.status} ${resp.statusText}`
        );
      }

      navigate("/", { state: { ref: "postSubmitted" } });
    } catch (e: unknown) {
      console.error(e);
      setSubmitting(false);
    }
  }

  switch (page) {
    case 0:
      return (
        <main>
          <SoundsInput
            initSounds={observation.sounds}
            onSubmit={(sounds, comment) => {
              setObservation({
                ...observation,
                sounds,
                other: { ...observation.other, sounds: comment },
              });
              setPage((page) => page + 1);
            }}
            onBack={onBack}
          />
        </main>
      );
    case 1:
      return (
        <main>
          <SmellsInput
            initSmells={observation.smells}
            onSubmit={(smells, comment) => {
              setObservation({
                ...observation,
                smells,
                other: { ...observation.other, smells: comment },
              });
              setPage((page) => page + 1);
            }}
            onBack={() => setPage((page) => page - 1)}
          />
        </main>
      );

    case 2:
      return (
        <main>
          <SightsInput
            observation={observation}
            setObservation={setObservation}
            onSubmit={() => setPage((page) => page + 1)}
            onBack={() => setPage((page) => page - 1)}
            fromEnd={sightsFromEnd}
          />
        </main>
      );

    case 3:
      return (
        <CommentInput
          onSubmit={(comment) => {
            const newObs = {
              ...observation,
              other: { ...observation.other, additional: comment },
            };
            setObservation(newObs);
            uploadObservation(newObs);
          }}
          onBack={() => {
            setPage((page) => page - 1);
            setSightsFromEnd(false);
          }}
          submitting={submitting}
          heading="Additional Observations"
          label="Submit post"
        />
      );

    default:
      throw Error(`Unknown observation page ${page}`);
  }
}