import * as React from "react";
import { SafetyText } from "../components/SafteyText";

export default function Safety() {

    return (
        <main>
            <SafetyText /> 
        </main>
    )
};