import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../pages/NotFound";
import { Search } from "../pages/Search";
import About from "../pages/About";
import ReportPost from "../pages/ReportPost";
import FilterSearch from "../pages/FilterSearch";
import UserSearch from "../components/UserSearch";
import Activate from "../pages/Activate";
import ProfileDetail from "../pages/ProfileDetail";
import Safety from "../pages/Safety";
import ReportUser from "../pages/ReportUser";
import SubmitPassReset from "../pages/SubmitPassReset";
import ConfirmPassReset from "../pages/ConfirmPassReset";
import ChangePass from "../pages/ChangePass";
import { VolcanoPosts } from "../pages/VolcanoPosts";
import { VolcanoSearch } from "../pages/VolcanoSearch";
import ReviewReports from "../pages/ReviewReports";
import ViewReportPost from "../pages/ViewReportPost";
import ViewReportUser from "../pages/ViewReportUser";
import Feedback from "../pages/Feedback";
import Home from "../pages/Home";
import MyProfile from "../pages/MyProfile";
import Register from "../pages/Register";
import { CreatePost } from "../pages/CreatePost";
import { ReviewPosts } from "../pages/ReviewPosts";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import { CurrentUser, roleSets } from "./permissions";
import { PostPage } from "../pages/PostPage";
import ReportedUserProfile from "../pages/ReportedUserProfile";
import RegistrationSuccess from "../pages/RegistrationSuccess";
import { ReportedPostPage } from "../pages/ReportedPostPage";

const RouteHandler = ({ currentUser }: { currentUser: CurrentUser }) => {
  let routesToRender: [string, JSX.Element][] = [];
  let sets = roleSets();
  /** Suspended may view these but get popups */
  routesToRender.push(
    ["/register", <Register />],
    ["/login", <Login />],
  )
  if (sets[0]?.roles.includes(currentUser.role)) {
    /** All but suspended */
    routesToRender.push(
      ["/", <Home />],
      ["/search", <Search />],
      ["/safety", <Safety />],
      ["/activate/:uid/:token", <Activate />],
      ["/success", <RegistrationSuccess/>],
      ["/submit-reset", <SubmitPassReset />],
      ["/password-reset/:uid/:token", <ConfirmPassReset />],
      ["/volcanosearch", <VolcanoSearch />],
      ["/volcano/:volcano", <VolcanoPosts />],
      ["/about", <About />],
      ["/post/:id", <PostPage />],
    );
  }
  if (sets[1]?.roles.includes(currentUser.role)) {
    /**All but public + suspended */
    routesToRender.push(
      ["/post", <CreatePost />],
      ["/myprofile", <MyProfile />],
      ["/profile/:uid/", <ProfileDetail />],
      ["/logout", <Logout />],
      ["/reportPost/:pid", <ReportPost />],
      ["/reportUser/:uid", <ReportUser />],
      ["/filtersearch", <FilterSearch />],
      ["/usersearch", <UserSearch />],
      ["/password-change", <ChangePass />],
      ["/feedback", <Feedback />],
    );
  }
  if (sets[2]?.roles.includes(currentUser.role)) {
    /**Admin + volcanologist */
    routesToRender.push(["/review", <ReviewPosts />]);
  }
  if (sets[3]?.roles.includes(currentUser.role)) {
    /**Admin + mod */
    routesToRender.push(
      ["/reviewreports", <ReviewReports />],
      ["/viewreportuser", <ViewReportUser />],
      ["/viewreportpost", <ViewReportPost />],
      ["/reporteduserprofile/:uid/", <ReportedUserProfile />],
      ["/reviewpost/:id", <ReportedPostPage />],
    );
  }

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {routesToRender.map(([path, element]) => (
        <Route path={path} key={path} element={element} />
      ))}
    </Routes>
  );
};

export default RouteHandler;
