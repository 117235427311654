import { DateTime } from "luxon";

export type Weather = {
  id?: string;
  windSpeed?: string;
  windDirection?: string;
  humidity?: string;
  temperature?: string;
  pressure?: string;
  datetime?: DateTime;
};

export type RawWeather = {
  weather_id?: string;
  wind_speed?: string;
  wind_direction?: string;
  humidity?: string;
  temperature?: string;
  pressure?: string;
  weather_datetime?: string;
}

export function fromRawWeather(untyped: unknown): Weather {
  const raw = untyped as RawWeather;
  return {
    id: raw.weather_id,
    windSpeed: raw.wind_speed,
    windDirection: raw.wind_direction,
    humidity: raw.humidity,
    temperature: raw.temperature,
    pressure: raw.pressure,
    datetime: raw.weather_datetime ? DateTime.fromISO(raw.weather_datetime, { setZone: true }) : undefined
  }
}