import * as React from "react";
import { ErrorPage } from "../components/ErrorPage";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { Box, Button, CircularProgress } from "@mui/material";
import type { ReportDetail } from "../Report";
import type { UserInfo } from "../UserInfo";
import { Link } from "../components/Link";
import { useNavigate } from "react-router-dom";
import { LogoIcon } from "../images/LogoIcon";


type State =
  | {
    step: "fetching";
  }
  | {
    step: "list";
  };

function ViewReportUser () {
    const [state, setState] = React.useState<State>({ step: "fetching" });
    const [reportInfo, setReportInfo] = React.useState<ReportDetail[]>(); //List of reports
    const [reportedUser, setreportuser] = React.useState<UserInfo[]>([]); //List of reported userid/username
    const navigate = useNavigate();
    
    React.useEffect(() => {
      const getData = setTimeout(() => {
        fetch(`/api/reports/allUserReports/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => res.json())
          .then(async (json) => {
            setReportInfo(json)
            /*
            console.log("test", reportInfo?.find((obj) => {
              return obj.report_id === 85 //for jackfinaltest
            }))
            */
            console.log('report list', json);
          });

          fetch('/api/reports/getAllReportedUser/', {
              headers: {
                  method: "GET",
                  Authorization: `JWT ${localStorage.getItem('token')}`,
              }
          })
          .then((res) => {
              return res.json();
          })
          .then(data => {
              
              setreportuser(data);
              setState({step: "list"});
          })

      }, 500);
      return () => clearTimeout(getData);
              
    }, [])

 

    const dateFormat = (date:any) =>{
        const format = DateTime.fromISO(date)
        const humanReadable = format.toLocaleString();
        return humanReadable;
      }
    
    
    const retrieveUsername = (uid: number) => {
      return reportedUser?.find((obj) => obj.user_id === uid)?.username;
    }
    
      const columns: GridColDef[] = [
        { 
          field: 'report_id', 
          headerName: 'Id', 
          width: 90,
        },
        {
            field: 'reported_user',
            headerName: 'User Id',
            width: 150,
            //editable: true,
            renderCell: (params) => {
              return (
                <Link key={params.row.reported_user} onClick={() => navigate(`/reporteduserprofile/${params.row.reported_user}`, { state: { uid: params.row.reported_user } })} to={""}>
                {params.row.reported_user}
                </Link> 
              );
            }
          },
          
        {
            field: 'Username',
            headerName: 'Reported User',
            width: 160,
            valueGetter: (params: GridValueGetterParams) =>
            `${retrieveUsername(params.row["reported_user"])}`,
          },   
            
        {
          field: 'content',
          headerName: 'Content',
          type: 'boolean',
          width: 150,
          //editable: true,
        },
        {
          field: 'spam',
          headerName: 'Spam',
          type: 'boolean',
          width: 150,
          //editable: true,
        },
        {
          field: 'plagiarism',
          headerName: 'Plagiarism',
          type: 'boolean',
          width: 110,
          //editable: true,
        }, /*
        {
          field: 'ai_generated',
          headerName: 'AI Generated',
          type: 'boolean',
          width: 110,
          //editable: true,
        },
        {
          field: 'stock_photo',
          headerName: 'Stock Photo',
          type: 'boolean',
          width: 110,
          //editable: true,
        }, */
        {
          field: 'other',
          headerName: 'Other',
          type: 'boolean',
          width: 110,
          //editable: true,
        },
        {
          field: 'report_comments',
          headerName: 'Comment',
          type: 'string',
          width: 110,
          //editable: true,
        },
        /* {
          field: 'report_datetime',
          headerName: 'Report Date',
          type: 'date',
          width: 110,
          editable: true,
        }, */
        {
          field: 'date',
          headerName: 'Date',
          //description: 'This column has a value getter and is not sortable.',
          sortable: false,
          type: 'date',
          width: 160,
          valueGetter: (params: GridValueGetterParams) =>
            `${dateFormat(params.row["report_datetime"])}`,
        },
        {
          field: 'action',
          headerName: 'Action',
          sortable: false,
          width: 160,
          renderCell: (params) => {
            return (
              <Button
                onClick={() => deleteSingleReport(params.row.report_id)}
              >
                Delete
              </Button>
            );
          } 
        },
     
      ];
    
      async function deleteSingleReport(pid: number) {
        fetch(`/api/reports/deleteSingleReport/${pid}/`, {
            method: 'GET',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        })
        .then((res) => {
            if(res.ok) {
              window.location.reload();
    
            }
        })     
    }

    switch (state.step) {
        case "fetching":
            return <CircularProgress/>
        case "list":
            if (reportInfo === undefined || reportInfo?.length === 0) {
                return (
                    <ErrorPage
                      icon={<LogoIcon sx={{width: '100%', height: '100%'}}/>}
                      header="There are no reported users to review right now."
                      body="Check back later."
                    />
                )

            }
            return(
                <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={reportInfo!}
              getRowId={(row:any) => row.report_id}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              //checkboxSelection
              //disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main.light',
                },
              }}
            />
          </Box>
            )

    }
}

export default ViewReportUser;