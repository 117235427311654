import { Box, Button, Dialog, MenuItem, Stack } from "@mui/material";
import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { defaultUserInfo, UserInfo } from "../UserInfo";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

const DeleteAccount = (userid: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const [deleteUserInfo, setDeleteUserInfo] = React.useState<UserInfo>(
    defaultUserInfo()
  );

  let deleteAccount = async () => {
    setDeleteUserInfo({
      ...deleteUserInfo,
      user_id: Number(userid),
      delete_request_date: DateTime.now().plus({ days: 30 }),
    });
    await fetch(`/api/users/delete/`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(deleteUserInfo),
    });
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <MenuItem onClick={() => setOpen(true)}>Delete account</MenuItem>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <Box
            style={{
              padding: "50px",
            }}
          >
            <div>
              <p>
                <strong>WARNING:</strong>You are about to delete your account.{" "}
              </p>
              <p>
                <strong>This action cannot be undone.</strong>
              </p>
              <div>
                <Stack direction="column" spacing={2}>
                  <Button
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={() => deleteAccount()}
                  >
                    Delete
                  </Button>
                  <Button color="secondary" onClick={() => setOpen(false)}>Go Back</Button>
                </Stack>
              </div>
            </div>
          </Box>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteAccount;
