import * as React from "react";
import { Cancel } from "@mui/icons-material";
import { Link } from "./Link";
import MuiLink from "@mui/material/Link";

type Props = {
  icon?: React.ReactNode;
  header?: React.ReactNode;
  body?: React.ReactNode;
};

export function ErrorIndicator({ icon, header, body }: Props) {
  icon ??= <Cancel style={{ width: "100%", height: "100%" }} />;
  header ??= "Something went wrong.";
  body ??= (
    <>
      Try <MuiLink href={window.location.href}>reloading the page</MuiLink> or{" "}
      <Link to="/">going home</Link>.
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          color: "#eee",
          width: "150px",
          height: "150px",
        }}
      >
        {icon}
      </div>
      <div>
        <h3>{header}</h3>
        <p>{body}</p>
      </div>
    </div>
  );
}

export function ErrorPage(props: Props) {
  return (
    <main
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ErrorIndicator {...props} />
    </main>
  );
}
