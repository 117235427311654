import * as React from "react";
import { Avatar, Box } from "@mui/material";
import { Post, comparePosts, fromRawPost } from "../Post";
import { CircularProgress } from "@mui/material";
import { UserMenu } from "../components/UserMenu";
import { ErrorIndicator } from "../components/ErrorPage";
import { LogoIcon } from "../images/LogoIcon";
import { PostGallery } from "../components/PostGallery";

function MyProfile() {
  const [username, setUsername] = React.useState<string>();
  const [userID, setID] = React.useState<string>();

  const [posts] = React.useState<Promise<Array<Post>>>(() =>
    fetch(`/api/posts/profilepost/${localStorage.getItem("userID")}`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(async (rawPosts: Array<unknown>) => {
        const posts = await Promise.all(rawPosts.map(fromRawPost));
        posts.sort(comparePosts);
        return posts;
      })
  );

  React.useEffect(() => {
    fetch("/api/users/current/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setUsername(json.username);
        setID(json.user_id);
      });
  }, []);

  if (username === undefined || userID === undefined) {
    return <CircularProgress sx={{ marginTop: "50%" }} />;
  }

  return (
    <main>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Hello {username}</h1>
        <Avatar sx={{ m: 1, bgcolor: "darkred" }}>
          <LogoIcon />
        </Avatar>

        <UserMenu username={username} userid={userID} />
      </Box>
      <PostGallery
        galleryTitle={<h2 style={{ textAlign: "center" }}>Your posts</h2>}
        posts={posts}
        emptyListPlaceholder={
          <ErrorIndicator
            icon={<LogoIcon style={{ width: "100%", height: "100%" }} />}
            header={`You haven't made any posts yet.`}
            body=""
          />
        }
      />
    </main>
  );
}
export default MyProfile;
