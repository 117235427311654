import * as React from "react";
import { ErrorPage } from "../components/ErrorPage";
import { QuestionMark } from "@mui/icons-material";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <ErrorPage
      icon={<QuestionMark style={{ width: "100%", height: "100%" }} />}
      header="Page not found"
      body={
        <>
          Check the URL, or <Link to="/">Go Home</Link> to find what you're
          looking for.
        </>
      }
    />
  );
}