import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PWAInstaller from '../PWAInstaller';

export default function PWAInstallAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      console.log(event)
    };

  return (
    <div>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>About Progressive Web Apps</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            Did You See It is a progressive web app which means that you can easily install it onto the home screen of
            your mobile device or the desktop of your computer. Progessive web apps function like mobile apps from the app
            store but are delivered through your browser.
          </Typography>
          <br/>
          <Typography>
            Even if you are miles from the nearest mobile connection, you will be able to create a post that will 
            automatically save on your device and uploaded to Did You See It when your device re-connects to the internet.
            As Did You See It continues to improve, progressive web apps make sure you have the most up to date version 
            every time you open the app, ensuring you don't miss out on any new or improved features.
          </Typography>
          <br/>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            iOS
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Install on iPhone and iPad</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            1. Use Safari to visit Didyouseeit.org.
          </Typography>
          <Typography>
            2. Click on the share icon located at the center bottom of the screen (iPhone), or top of screen (iPad).
          </Typography>
          <Typography>
            3. Scroll and select Add to Home Screen.
          </Typography>
          <Typography>
            5. Click Add.
          </Typography>
          <Typography>
            If you were successful, Did You See It will now appear as an app on the home screen of your device.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Android</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Install on Android mobile devices
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PWAInstaller/>
          <Typography>          
            How to  install Did You See It.
          </Typography>
          <Typography>          
            1. Go to DidYouSeeIt.org in your preferred browser*.
          </Typography>
          <Typography>          
            2. Tap on settings, usually three dots in the top right.
          </Typography>
          <Typography>          
            3. Tap Install.
          </Typography>
          <Typography>          
            4. Confirm by tapping Add.
          </Typography>
          <Typography>
            * The following browsers are compatible with progressive web apps on Android: Firefox, Google Chrome,
            Samsung Internet, Microsoft Edge, Opera, Brave, Huawei Browser, Baidu, and UCWeb.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            PC
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Install on computers running Windows OS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PWAInstaller/>
          <Typography>          
            How to install Did You See It
          </Typography>
          <Typography>          
            1. Go to Didyouseeit.org using Chrome or Edge. 
          </Typography>
          <Typography>          
            2. In the address bar and find the download app icon, usally a computer screen with an arrow pointing down.
          </Typography>
          <Typography>          
            3. Click Install.
          </Typography>
          <Typography>
            4. Follow the on screen instructions to install Did You See It.
          </Typography>
          <Typography>
            If you do not see the icon in the address bar, your browser or computer may not be compatible with progressive web apps. 
            You may need to update your browser or operating system.  
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Mac</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Install on computers running macOS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            How to install Did You See It
          </Typography>
          <Typography>          
            1. Go to Didyouseeit.org using your preferred browser.
          </Typography>
          <Typography>          
            2. In the address bar, select the download app button.
          </Typography>
          <Typography>          
            3. Click Install.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}