import { useEffect, useState } from "react";

export type FetchResult<T> =
  | {
      step: "fetching";
    }
  | {
      step: "done";
      value: T;
    }
  | {
      step: "error";
      error: unknown;
    };

export function useFetch<T>(input: RequestInfo | URL, {
  map,
  recover,
  init: requestInit,
  cached,
}: {
  map?: (value: unknown) => T | PromiseLike<T>;
  recover?: (error: unknown) => T | PromiseLike<T>;
  init?: RequestInit;
  cached?: T;
}) {
  const stateInit: FetchResult<T> =
    cached !== undefined
      ? { step: "done", value: cached }
      : { step: "fetching" };
  const [result, setResult] = useState<FetchResult<T>>(stateInit);

  useEffect(() => {
    const controller = new AbortController();
    const signal =
      requestInit !== undefined && "signal" in requestInit
        ? requestInit.signal
        : controller.signal;

    fetch(input, { signal, ...requestInit })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(map, recover)
      .then(
        (value) => setResult({ step: "done", value }),
        (error) => setResult({ step: "error", error })
      );

    return () => controller.abort();
  }, [input]);

  return result;
}