import {DateTime} from "luxon";

export type Report = {
    content: boolean;
    spam: boolean;
    plagiarism: boolean;
    ai_generated: boolean;
    stock_photo: boolean;
    other: boolean;
    report_comments: string;
    report_status: reportStatus;
    reviewed_by: number | null;
    report_datetime: DateTime;
    reporting_user: number;
    reported_post: number | null;
    reported_user: number | null;
    report_type: reportType;

}
export type reportStatus = "" | "reported" | "pending" | "reviewed";
export type reportType = "user" | "post" | null;
export type Reasons = {
    content: boolean;
    spam: boolean;
    plagiarism: boolean;
    ai_generated: boolean;
    stock_photo: boolean;
    other: boolean;
};

export const REASON_DISPLAY: { [key in keyof Reasons]: string } = {
    content: "Content",
    spam: "Spam",
    plagiarism: "Plagiarism",
    ai_generated: "This image is AI generated",
    stock_photo: "This is a stock photo",
    other: "Other",
};

export function defaultReport(): Report {
    return {
        content: false,
        spam: false,
        plagiarism: false,
        ai_generated: false,
        stock_photo: false,
        other: false,
        report_comments : "",
        report_status : "",
        reviewed_by : null,
        report_datetime: DateTime.now(),
        reporting_user: -1,
        reported_post: null,
        reported_user: null,
        report_type: null,
    }
}

export interface ReportDetail {
    report_id: number;
    reporting_user: number;
    content: boolean;
    spam: boolean;
    plagiarism: boolean;
    ai_generated: boolean;
    stock_photo: boolean;
    other: boolean;
    report_comments: string;
    report_status: string;
    reviewed_by: number | null;
    report_datetime: string;
    reported_post: number | null;
    reported_user: number | null;
    report_type: string;
  }