import * as React from "react";
import { useParams } from "react-router-dom";
import { PostGallery } from "../components/PostGallery";
import { describeObservation } from "../Observation";
import { comparePosts, fromRawPost, Location, Post } from "../Post";
import { useVolcano } from "../Volcano";
import { useConversions } from "../utils/conversions";

export function VolcanoPosts() {
  const volcanoNumber = Number(useParams()["volcano"]);
  const volcano = useVolcano(volcanoNumber);
  const conversions = useConversions();
  const [posts, _] = React.useState<Promise<Post[]>>(() =>
    fetch(`/api/posts/search?volcano_number=${volcanoNumber}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            `Error fetching posts: ${res.status} ${res.statusText}`
          );
        }
      })
      .then((posts: unknown[]) =>
        Promise.all(posts.map((post) => fromRawPost(post)))
      )
      .then((posts) => {
        posts.sort(comparePosts);
        return posts;
      })
  );

  const info =
    volcano === undefined
      ? []
      : [
          [volcano.id, "Global Volcanism Program Number"],
          [volcano.primaryVolcanoType, "Volcano Type"],
          [volcano.country, "Country"],
          [volcano.region, "Region"],
          [formatLocation(volcano.location), "Location"],
          [
            volcano.elevation
              ? conversions.m(Number(volcano.elevation))
              : undefined,
            "Elevation",
          ],
          [volcano.tectonicSetting, "Tectonic Setting"],
          [volcano.majorRockType, "Major Rock Type"],
          [volcano.lastEruptionYear, "Last Eruption Year"],
          [volcano.geologicEpoch, "Geologic Epoch"],
        ];

  const trackWidth = "300px";
  const colGap = "50px";
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(${trackWidth}, 1fr))`,
    gap: `20px ${colGap}`,
    width: `calc(min(${trackWidth} * 2 + ${colGap} * 3, 100%))`,
    alignSelf: "center",
  };

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 60px)",
        width: "min(100%, 850px)",
        padding: "0 20px 20px",
        justifyContent: "stretch",
        alignItems: "center",
      }}
    >
      {volcano === undefined ? undefined : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 0 30px",
            width: "100%",
            marginLeft: "16px", 
          }}
        >
          <h1 style={{ textAlign: "center" }}>{volcano.name}</h1>
          <div style={gridStyle}>
            {info.map(([datum, desc]) => {
              if (datum === undefined) {
                return undefined;
              }

              return (
                <div key={desc}>
                  <h3 style={{ margin: "0 0 5px" }}>{desc}</h3>
                  <div>{datum}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <PostGallery
        posts={posts}
        galleryTitle={<h2 style={{ textAlign: "center" }}>Recent Posts</h2>}
        postPreviewTitle={(post) => describeObservation(post.observation)}
      />
    </main>
  );
}

function formatLocation({ lat, long }: Location) {
  const latPrefix = lat > 0 ? "N" : "S";
  const longPrefix = long > 0 ? "E" : "W";
  return `${Math.abs(lat)}°${latPrefix}, ${Math.abs(long)}°${longPrefix}`;
}
