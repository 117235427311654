import { Box, Button } from "@mui/material";
import type { Post } from "../Post";
import { DateTime } from "luxon";
import {
  Sights,
  SIGHT_DISPLAY,
  Smells,
  SMELL_DISPLAY,
  Sounds,
  SOUND_DISPLAY,
} from "../Observation";
import * as React from "react";
import Container from "@mui/material/Container";
import { Link } from "./Link";
import { BackButtonHeader } from "./BackButtonHeader";
import { CurrentUserContext, Restricted } from "../utils/permissions";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";
import RemoveObservations from "./RemoveObservations";

export function PostView({
  post,
  overlay,
  onBack,
}: {
  post: Post;
  overlay?: JSX.Element;
  onBack: () => void;
}) {
  const obsSectionStyle = {
    margin: "10px 0",
  };

  const obsListStyle = {
    display: "flex",
    columnGap: "10px",
  };
  const currentUser = React.useContext(CurrentUserContext);
  const [username, setUsername] = React.useState("");
  const navigate = useNavigate();
  React.useEffect(() => {
    const userId = post.uploadedBy;
    fetch(`/api/users/public/${userId}/`)
      .then((res) => res.json())
      .then((json) => {
        setUsername(json.username);
      });
  }, [post]);

  const [removeObservationsOpen, setRemoveObservationsOpen] =
    React.useState(false);
  const handleRemoveObservationsOpen = () => setRemoveObservationsOpen(true);
  const handleRemoveObservationsClose = () => setRemoveObservationsOpen(false);

  const handleObservationUpdate = (updatedSights: Sights) => {
    post.observation.sights = updatedSights;
  };

  const handleEditObservationsButton = () => {
    handleRemoveObservationsOpen();
  };

  const [isOpenPost, setIsOpenPost] = React.useState(false);
  const togglePopupPost = () => {
    setIsOpenPost(!isOpenPost);
    navigate(-1);
  };

  const [confirmDeletePost, setConfirmDeletePost] = React.useState(false);
  const toggleConfirmDeletePost = () => {
    setConfirmDeletePost(!confirmDeletePost);
  };
  async function deletePost(pid: any) {
    fetch(`/api/reports/deletePost/${pid}/`, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.ok) {
        setIsOpenPost(true);
        toggleConfirmDeletePost();
        console.log(res);
      }
    });
  }

  const checkedSounds = Object.entries(post.observation.sounds).filter(
    ([_, checked]) => checked
  );

  const checkedSmells = Object.entries(post.observation.smells).filter(
    ([_, checked]) => checked
  );

  const checkedSights = Object.entries(post.observation.sights).filter(
    ([_, answer]) => answer === "yes"
  );

  const checkPending = post.status;

  let temperature;
  let windSpeed;
  let pressure;
  let tempUnit = "C";
  let speedUnit = "meters/sec";
  let pressureUnit = "hPa";

  if (post.weather) {
    temperature = Number(post.weather.temperature) - 273.15;
    windSpeed = Number(post.weather.windSpeed);
    pressure = Number(post.weather.pressure);
    if (currentUser.role !== "public" && !currentUser.metric) {
      temperature = temperature * 1.8 + 32;
      tempUnit = "F";

      windSpeed *= 2.236936;
      speedUnit = "mph";

      pressure *= 0.02953;
      pressureUnit = "inHg";
    }

    temperature = Math.round(temperature * 10) / 10;
    windSpeed = Math.round(windSpeed * 10) / 10;
    pressure = Math.round(pressure * 10) / 10;
  }
  return (
    <main
      style={{
        paddingBottom: "20px",
      }}
    >
      <BackButtonHeader
        title={`${
          post.volcano.name
        } - ${post.observation.datetime.toLocaleString()}`}
        onBack={onBack}
      />
      <div
        style={{
          width: "min(800px, 100vw)",
          height: "max-content",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          href={String(post.observation.photo)}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={String(post.observation.photo)}
            alt={`Photograph of ${post.volcano.name}`}
            style={{
              maxHeight: "80vh",
              maxWidth: "100%",
            }}
          />
        </a>
      </div>
      <Container>
        <Box sx={obsSectionStyle}>
          <h3>Uploaded By</h3>
          <Link to={`/profile/${post.uploadedBy}`}>{username}</Link>
        </Box>

        {checkPending !== "pending" ? undefined : ( //If not pending, make the section empty. If pending, display when viewing post
          <Box sx={obsSectionStyle}>
            <h3>Status</h3>
            <Box sx={obsListStyle}>
              <div>{post.status}</div>
            </Box>
          </Box>
        )}

        <Box sx={obsSectionStyle}>
          <h3>Country</h3>
          <div>{post.volcano.country}</div>
        </Box>

        <Box sx={obsSectionStyle}>
          <h3>Date</h3>
          <div>
            { post.observation.time_unknown 
            ? post.observation.datetime.toLocaleString(DateTime.DATE_FULL)
            : post.observation.datetime.toLocaleString(DateTime.DATETIME_FULL)
            }
          </div>
        </Box>
        <Box sx={obsSectionStyle}>
          <h3>Volcano</h3>
          <Link to={`/volcano/${post.volcano.id}`}>{post.volcano.name}</Link>
        </Box>

        {checkedSounds.length === 0 ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Sounds</h3>
            <Box sx={obsListStyle}>
              {checkedSounds.map(([sound, _]) => {
                return (
                  <span key={sound}>
                    {SOUND_DISPLAY[sound as keyof Sounds]}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
        {checkedSmells.length === 0 ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Smells</h3>
            <Box sx={obsListStyle}>
              {checkedSmells.map(([smell, _]) => {
                return (
                  <span key={smell}>
                    {SMELL_DISPLAY[smell as keyof Smells]}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
        {checkedSights.length === 0 ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Sights</h3>
            <Box sx={obsListStyle}>
              {checkedSights.map(([sight, _]) => {
                return (
                  <span key={sight}>
                    {SIGHT_DISPLAY[sight as keyof Sights]}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
        {post.observation.other.additional === "" ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Additional Notes</h3>
            <div>{post.observation.other.additional}</div>
          </Box>
        )}
        {post.weather && (
          <Box sx={obsSectionStyle}>
            <h3>Weather</h3>
            <div>
              Temperature: {temperature}&deg; {tempUnit}
              <br />
              Wind Speed: {windSpeed} {speedUnit}
              <br />
              Wind Direction: {post.weather.windDirection}&deg;
              <br />
              Humidity: {post.weather.humidity}%
              <br />
              Pressure: {pressure} {pressureUnit}
              <br />
              Weather's Date:{" "}
              {post.weather.datetime
                ? post.weather.datetime.toLocaleString(DateTime.DATETIME_FULL)
                : ""}
            </div>
          </Box>
        )}

        {post.observation.location && (
          <Box sx={obsSectionStyle}>
            <h3>Geolocation</h3>
            <div>
              Latitude: {post.observation.location.lat}
              <br/>
              Longitude: {post.observation.location.long}
              <br/>
            </div>
          </Box>
        )}

        {post.status !== "pending" ? undefined : (
          <div>
            <Restricted role="volcanologist">
              <Button
                onClick={() => {
                  handleEditObservationsButton();
                }}
              >
                Edit observed sights
              </Button>
            </Restricted>
            <RemoveObservations
              postid={Number(post.id)}
              sights={post.observation.sights}
              open={removeObservationsOpen}
              handleClose={handleRemoveObservationsClose}
              handleUpdate={handleObservationUpdate}
            />
          </div>
        )}

        <div>
          <Button sx={{ mt: 2 }}>
            <Link
              to={`/reportPost/${post.id}`}
              underline="none"
              color="inherit"
            >
              Report this Post
            </Link>
          </Button>
        </div>

        <div>
          <Restricted role="moderator">
            <Button sx={{ mt: 2 }} onClick={toggleConfirmDeletePost}>
              Delete this Post
            </Button>
          </Restricted>
        </div>

        <Popup
          title="Delete Post"
          open={confirmDeletePost}
          onClose={toggleConfirmDeletePost}
          content={
            <>
              <p>
                Do you want to delete this post?{" "}
                <Button onClick={() => deletePost(post.id)}> Yes </Button>{" "}
                <Button onClick={toggleConfirmDeletePost}> No </Button>
              </p>
            </>
          }
        />
        <Popup
          title="Deletion"
          open={isOpenPost}
          onClose={togglePopupPost}
          content={
            <>
              <p>The selected post is deleted.</p>
            </>
          }
        />
        {overlay}
      </Container>
    </main>
  );
}
