import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./style/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material";
import type { TypographyStyleOptions } from "@mui/material/styles/createTypography";

const systemFonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "sans-serif",
].join(",");

const headingFont: TypographyStyleOptions = { fontFamily: `"Jost", ${systemFonts}`, fontWeight: "normal" };

const theme = createTheme({
  palette: {
    primary: { main: "#8B0000", dark: "#730000", light: "#A60000" },
    secondary: {
      main: "#FFF",
      dark: "#EEE",
      light: "#FFF",
      contrastText: "#8B0000",
    },
  },
  typography: {
    fontFamily: `"Inter", ${systemFonts}`,
    h1: headingFont,
    h2: headingFont,
    h3: headingFont,
    h4: headingFont,
    h5: headingFont,
    h6: headingFont,
  },
  components: {
    MuiButton: { defaultProps: { variant: "contained" } },
    MuiCard: { defaultProps: { variant: "outlined" } },
    MuiTextField: { defaultProps: { variant: "outlined" } },
    MuiLink: { defaultProps: { underline: "hover" } },
  },
});

const root = document.getElementById("root")!;

render(
  <>
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  </>,
  root
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
