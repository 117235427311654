import { useState, useEffect } from "react";
import type { Location } from "../Post";

const listeners: ((result: CompleteGeolocationResult) => void)[] = [];

function locationSuccess(location: Location) {
  for (const listener of listeners.splice(0, listeners.length)) {
    listener({ step: "done", result: location });
  }
}

function locationFailure(error: unknown) {
  for (const listener of listeners.splice(0, listeners.length)) {
    listener({ step: "error", error });
  }
}

type CompleteGeolocationResult =
  | { step: "done"; result: Location }
  | { step: "error"; error: unknown };

export type GeolocationResult = { step: "waiting" } | CompleteGeolocationResult;

export function useGeolocation() {
  const [state, setState] = useState<GeolocationResult>({ step: "waiting" });

  useEffect(() => {
    listeners.push(setState);
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: long } }) => {
        locationSuccess({ lat, long });
      },
      locationFailure
    );
  }, []);

  return state;
}