import { ArrowBack } from '@mui/icons-material'
import { Button, TextField } from '@mui/material'
import * as React from 'react'
import {useState} from 'react'
import { useNavigate } from 'react-router-dom'


type passChangeInfo = {
    new_password : string
    re_new_password : string
    current_password : string
}




const ChangePass = () => {

    const [info, setInfo] = useState<passChangeInfo>({
        new_password: "",
        re_new_password : "",
        current_password: ""
    })
    const [stateMsg, setStateMsg] = useState("")
    const navigate = useNavigate();

    let submitPassChange = async () => {
        console.log(info)
        fetch(`/auth/users/set_password/`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(info)
        }).then((response) => {
            if(response.status === 204){
                setStateMsg("Password Changed.")
                return response.json()
            }
            else {
                setStateMsg("Error Occurred.")
                console.log(response.json())
                throw response
            }
        })
    }
  return (
    <div>
        <h2>Change your password:</h2>
        {stateMsg}
        <div>
            <ArrowBack
            onClick={()=> {
                navigate(-1);
            }}/>

        </div>
        <div
        style={{display: 'flex', flexDirection: 'column'}}>
        <TextField
        margin="normal"
        type="password"
        placeholder="Current Password"
        onChange={(e) => {
            setInfo({...info, current_password : e.target.value})
        }}></TextField>
        <TextField
        margin="normal"
        type="password"
        placeholder="New Password"
        onChange = {(e) => {
            setInfo({...info, new_password : e.target.value})
        }}></TextField>
        <TextField
        margin="normal"
        type="password"
        placeholder="Confirm New Password"
        onChange = {(e) => {
            setInfo({...info, re_new_password : e.target.value})
        }}></TextField>
        <Button
            sx={{marginTop: '20px'}}
            onClick= {() => submitPassChange()}
        >
            Change Password
        </Button>
        </div>
        
      
    </div>
  )
}

export default ChangePass