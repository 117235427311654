import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HowItWorksAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      console.log(event)
    };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%'}}>
            Uploading A Photo and Observations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            You can take photos directly from your mobile device in real time, or upload photos saved on your phone or computer. After taking or selecting 
            a photo, we guide you through a series of prompts to collect additional observations about what you see, hear, and smell, to provide additional 
            data for scientists.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Weather Data and Human Detection</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            When a post is submitted, Did You See It automatically collects date, time, and location data from your picture, records the weather data on the 
            date the picture was taken,  and uses computer vision to blur out any individuals in the image, 
            protecting their privacy while making the data available globally in compliance with privacy laws in different countries.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%' }}>
            Human Review of Every Post
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            Every submission is reviewed by a scientist before being published. These reviewers providing feedback and engage with our community of citizen scientists. 
            Our goal is to create a collaborative platform that encourages users to contribute to the study of volcanos and receive feedback on their submissions. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Post is Published</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            Approved posts can be accessed by anyone with an internet connection and contribute to the advancement of volcanic research.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}