import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LocationServicesAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      console.log(event)
    };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            iOS
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Using Did You See It as an app or from the browser on iPhone or iPad</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            1. From the home screen of your device open the Settings app
          </Typography>
          <Typography>
            2. Tap or search for Privacy & Security
          </Typography>
          <Typography>
            3. Tap on Location Services
          </Typography>
          <Typography>
            4. Scroll to the browser you are using (if installed as an app on the homescreen select Safari Websites)
          </Typography>
          <Typography>
            5. Select 'While Using the App' or 'Ask Next Time Or When I Share'
          </Typography>
          <Typography>
            6. If you selected ask next time in the previous step when you return to Did You See It, it will prompt you to 
            turn on location services for Did You See It
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Android</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Using Did You See It as an app or from the browser on an Android device
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            1. From your browser navigate to DidYouSeeIt.org
          </Typography>
          <Typography>
            2. Tap the lock icon in the address bar
          </Typography>
          <Typography>
            3. Tap Permissions
          </Typography>
          <Typography>
            4. Change the setting to allow Did You See It to use your location
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            PC
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Using Did You See It from a computer running Windows OS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            1. Go to DidYouSeeIt.org in your preferred browser
          </Typography>
          <Typography>          
            2. In the address bar tap the location services icon, usally looks like a map pin
          </Typography>
          <Typography>          
            3. Select the option that allows Did You See It to use location services
          </Typography>
          <Typography>          
            4. Tap ok to save the changes
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Mac</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Using Did You See It from a Mac running macOS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>          
            1. From the Apple menu select System Settings
          </Typography>
          <Typography>          
            2. Click Privacy & Security in the sidebar
          </Typography>
          <Typography>          
            3. Click Location Services on the right (you may need to scroll down)
          </Typography>
          <Typography>          
            4. Find and select your browser from the list of apps
          </Typography>
          <Typography>          
            5. Select your preferred option that allows Did You See It to use location services
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}