import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle({
  children,
  onClose,
  ...other
}: {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}) {
  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    </div>
  );
}
const Popup = ({
  open,
  onClose,
  title,
  content,
  style,
}: {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <div style={style}>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
        >
          <b style={{ fontSize: "2rem" }}>{title}</b>
        </BootstrapDialogTitle>
        <DialogContent>{content}</DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default Popup;
