import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Divider } from "@mui/material";
import { AcceptDeny } from "./AcceptDeny";
import { SizedFixedImage } from "./SizedImage";
import Popup from "./Popup";
import ExifReader from "exifreader";
import { DriveFolderUpload, PhotoCamera } from "@mui/icons-material";
import type { Location } from "../Post";
import { DateTime } from "luxon";

export type Metadata = {
  datetime: DateTime | undefined;
  fileModified: DateTime;
  location: Location | undefined;
  source: "take" | "choose";
};

//Function to take photo from file(computer) or camera(mobile)
function Upload({
  onSubmit,
}: {
  onSubmit: (photo: URL, metadata: Metadata) => void;
}) {
  //Store the image in as 'source' Use to pass on photo data
  const [source, setSource] = React.useState<URL>();
  const [metadata, setMetadata] = React.useState<Metadata>();
  const [warningVisible, setWarningVisible] = React.useState(false);

  function closePopup() {
    setWarningVisible(false);
    document.getElementById("take-photo-input")!.click();
  }

  if (source !== undefined) {
    return (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <h1>Upload this photo?</h1>
          <br />
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SizedFixedImage src={String(source)} alt="Selected photograph" />
            <div
              style={{
                position: "relative",
                bottom: -50,
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <AcceptDeny
                setState={(accepted) =>
                  accepted ? onSubmit(source, metadata!) : setSource(undefined)
                }
              />
            </div>
          </div>
        </Box>
      </>
    );
  }

  const handleCapture = async (target: HTMLInputElement, source: "take" | "choose") => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]!;
        extractExifData(file, source).then(setMetadata);
        const dataUrl = await fileToDataUrl(file);
        setSource(new URL(dataUrl));
      }
    }
  };

  return (
    <main
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Popup
        title="Safety Warnings"
        open={warningVisible}
        onClose={closePopup}
        content={
          <>
            <p>1. Keep your eyes on the volcano.</p>
            <p>2. Protect yourself from ash, wear safety glasses and a mask.</p>
            <p>
              3. Avoid low standing areas to escape invisible poisonous gases.
            </p>
            <p>4. If you feel the lava heat, step back.</p>
            <p>5. Follow the direction of authorities and posted signs.</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Button onClick={closePopup}>Ok</Button>
            </div>
          </>
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <h1>Create a new post</h1>
        <TakePhotoButton
          handleCapture={handleCapture}
          setWarningVisible={setWarningVisible}
        />
        <Box sx={{ padding: "20px 0" }}>
          <Divider>OR</Divider>
        </Box>
        <Button component="label" startIcon={<DriveFolderUpload />}>
          Choose Photo
          <input
            type="file"
            accept="image/*"
            id="inputId"
            onChange={(e) => handleCapture(e.target, "choose")}
            style={{ display: "none" }}
          />
        </Button>
      </div>
    </main>
  );
}
export default Upload;

function fileToDataUrl(file: File): Promise<string> {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      res(reader.result! as string);
    });

    reader.addEventListener("error", rej);

    reader.readAsDataURL(file);
  });
}

async function extractExifData(file: File, source: "take" | "choose"): Promise<Metadata> {
  // Using ExifReader.load to get the exif data from file.
  // Including the option 'expanded' to bucket the data to make it easier to understand.
  const { exif, gps } = await ExifReader.load(file, { expanded: true });
  const location = gps?.Latitude === undefined || gps?.Longitude === undefined
  ? undefined
  : { lat: gps.Latitude, long: gps.Longitude };

  // Use null-ish coalescing to default to undefined if there is no data.
  const formattedDatetime =
    exif?.DateTime?.value[0] ??
    exif?.DateTimeOriginal?.value[0] ??
    exif?.DateTimeDigitized?.value[0];

  let datetime: DateTime | undefined = undefined;

  if (formattedDatetime !== undefined) {
    datetime = DateTime.fromFormat(formattedDatetime, "yyyy:MM:dd HH:mm:ss");
  }

  return {
    datetime,
    fileModified: DateTime.fromMillis(file.lastModified),
    location,
    source
  };
}

function TakePhotoButton({
  handleCapture,
  setWarningVisible,
}: {
  handleCapture: (target: HTMLInputElement, source: "take" | "choose") => void;
  setWarningVisible: (visible: boolean) => void;
}) {
  const [hasWarned, setHasWarned] = React.useState(false);
  if (hasWarned) {
    return (
      <Button component="label" startIcon={<PhotoCamera />}>
        Take Photo
        <input
          type="file"
          accept="image/*"
          id="take-photo-input"
          capture="environment"
          onChange={(e) => handleCapture(e.target, "take")}
          style={{ display: "none" }}
        />
      </Button>
    );
  } else {
    return (
      <Button
        startIcon={<PhotoCamera />}
        onClick={() => {
          setHasWarned(true);
          setWarningVisible(true);
        }}
      >
        Take Photo
      </Button>
    );
  }
}
