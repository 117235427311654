import * as React from 'react';
import { Button, Dialog, IconButton, Theme, useMediaQuery} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';







const EULApopover = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const useStyles = makeStyles((theme: Theme) => ({
    headerFont: {
    fontFamily: theme.typography.h5.fontFamily,
    fontWeight: 'bold',
    fontColor: 'white',
    textTransform: 'none',
    marginLeft: isMobile ? 7 : 10,
    },
  }));  

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const classes = useStyles();
    
  return (
    <div>
        <Button
        className={classes.headerFont}
        onClick={handleOpen}
        style = {{
          width: isMobile ? 0 : 10

        }}>
        Terms
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style = {{padding: '20px'}}>
          <IconButton
          onClick={handleClose}>
            <ArrowBack/>
          </IconButton>

          <h2 id="simple-modal-title">User License Agreement For Did You See It,
          A Software Application For Data Collection at Geological Locations and Events.</h2>

          <p id="simple-modal-description">
          <strong>Did You See It</strong> is a software application licensed (the <strong>“Licensed Application”</strong>) to a user (<strong>“Licensee”</strong>) 
          by Ariana Soldati, PhD, located at 2800 Faucette Drive, 1131 Jordan Hall, Campus Box 8208, North Carolina State University,
           Raleigh, North Carolina 27695 (<strong>"Licensor"</strong>), for use only under the terms of this agreement (the <strong>“License Agreement”</strong>).
          </p>

          <p><strong>
          By downloading the Licensed Application and any update thereto (as permitted by this License Agreement), 
          Licensee agrees to be bound by all the terms and conditions of this License Agreement.
          </strong></p>

          <p><strong>
          Did You See It, when downloaded, is licensed to Licensee for its intended uses only under the terms of this License Agreement. 
          The Licensor reserves all rights not expressly granted to Licensee. Did You See It is intended to be used on devices that 
          operate with browsers, primarily desktop and mobile devices.
          </strong></p>

          <p><strong>
          1. THE APPLICATION
            </strong></p>
            <p>Did You See It ("Licensed Application") is a piece of software created to upload/download and publicly share pictures of 
              volcanic events with camera data and Licensee observations. The Licensed Application also features text communication or 
              email communication to Licensees who contribute data to the Licensed Application.  </p>

            <p><strong>2. SCOPE OF LICENSE</strong>
            
            </p>
            <p>
            2.1 	Licensee is given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application 
            only on devices that the Licensee owns or controls.  
            </p>
            <p>
            2.2 	This License Agreement will also govern any updates of the Licensed Application provided by Licensor that replace, repair, 
            and/or supplement the first Licensed Application, unless a separate License Agreement is provided for such update, in which case 
            the terms of that new License Agreement will govern.
            </p>
            <p>
            2.3 	Licensee may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative 
            works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof 
            (except with Licensor's prior written consent).
            </p>
            <p>
            2.4 	Violations of the obligations in section 2.3, as well as the attempt of any kind of infringement, may be subject to prosecution and damages.
            </p>
            <p>
            2.5 	Licensor reserves the right to modify the terms and conditions of the License Agreement.
            </p>
            <p><strong>
            3. TECHNICAL REQUIREMENTS
            </strong></p>
            <p>
            3.1 	Licensee acknowledge that it is Licensee's responsibility to confirm and determine that the device on which Licensee intends to use the 
            Licensed Application satisfies the technical specifications needed to operate the Licensed Application.
            </p>
            <p>
            3.2 	Licensor reserves the right to modify the technical specifications as they see appropriate at any time.
            </p>
            <p><strong>
            4. NO MAINTENANCE OR SUPPORT
            </strong>
            </p>
            <p>
            4.1 	Licensor does not expressly or by implication agree to be obligated to provide any maintenance, technical or other support for the Licensed Application.            </p>
            <p>
            4.2 	Licensee acknowledges that the Licensor has no obligation whatsoever to furnish any maintenance and support services with respect to the Licensed Application.
            </p>
            <p>
            <strong>5. USE OF DATA</strong> 
            </p>
            <p>
            5.1	Licensee acknowledges that Licensor will be able to access, approve and adjust Licensee's contributed content and personal profile information (their “Contributions”),
             and that Licensor's use of such Contributions is subject to Licensor's privacy policy, which can be accessed in the account profile made by the Licensee.
            </p>
            <p>
            5.2	Licensee acknowledges that the Licensed Application collects and uses technical data and related information contained in Licensee's Contributions of uploaded photos 
            for purposes of providing all users with time and location specific data values related to events depicted in the Contributions. 
            </p>
            <p><strong>
            6. USER-GENERATED CONTRIBUTIONS
            </strong>
            </p>
            6.1	The Licensed Application may interact with the Licensee to communicate about their Contributions to the Licensed Application, including but not limited to text or email questions, comments, and suggestions.  Contributions will be viewable by other users of the Licensed Application.  As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:
a.	Licensee is the creator and owner of or has the necessary licenses, rights, consents, releases, and permissions to use and to authorize the Licensed Application and other users of the Licensed Application to use their Contributions in any manner allowed by the Licensed Application and this License Agreement.
b.	The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of Licensee's Contributions does not and will not infringe any proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, moral or similar rights of the Licensee or any third party.  
c.	Licensee has the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner intended by the Licensed Application and this License Agreement.
d.	Licensee's Contributions are not false, inaccurate, or misleading.
e.	Licensee's Contributions are not advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
f.	Licensee's Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by the Licensor).
g.	Licensee's Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone or any theoretical concept.
h.	Licensee's Contributions are not used to harass or threaten (in the legal sense of those terms) any other person or to promote violence against a specific person or class of people.
i.	Licensee's Contributions do not violate any applicable law, regulation, or rule.
j.	Licensee's Contributions do not violate the privacy or publicity rights of any third party.
k.	Licensee's Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.
l.	Licensee's Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
m.	Licensee's Contributions do not otherwise violate, or link to material that violates, any provision of this License Agreement, or any applicable law or regulation.

            <p>
            6.2	Any use of the Licensed Application in violation of the foregoing violates this License Agreement and may result in, among other things, termination or suspension of 
            Licensee's rights to use the Licensed Application.
            </p>
            <p>
            <strong>7. CONTRIBUTION LICENSE</strong>
            </p>
            <p>
            7.1	Licensee agrees that Contributions to the Licensed Application can become part of the public domain, freely accessed, 
            and used by any interested party.  By posting a Contribution to any part of the Licensed Application, Licensee automatically 
            grants, represents, and warrants that Licensee has the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, 
            non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, 
            resell, publish, broadcast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in part), 
            and distribute such Contributions for any purpose, and to prepare derivative works of, or incorporate in other works and grant and authorize
             sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.         
            </p>
            <p>
            7.2	This license will apply to any form, media, or technology now known or hereafter developed, and includes Licensor's use of your name, 
            company name, or franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial 
            images you provide.  Licensee waives all moral rights in Licensee's Contributions, and you warrant that moral rights have not otherwise been
             asserted in your Contributions.
            </p>
            <p>
            7.3	Licensee is solely responsible for their Contributions to the Licensed Application, and you expressly agree to exonerate the Licensor 
            from any and all responsibility and to refrain from any legal action against the Licensor regarding your Contributions.
            </p>
            <p>
            7.4	Licensor has the right, in their sole and absolute discretion, 
a.	to edit, correct, redact, or otherwise change any Contributions; 
b.	to recategorize any Contributions to place them in more appropriate context in the Licensed Application; and 
c.	to prescreen or delete any Contributions at any time and for any reason, without notice. 

            </p>
            <p>
            7.5	Licensor has no obligation to monitor Contributions within a certain time frame.  
            </p>
            <p>
            7.6	Contributions will be made publicly available at the discretion and timing of the Licensor or its workers.
            </p>
            <p>
            <strong>8. LIABILITY</strong>
            </p>
            <p>
            8.1 	Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of this License Agreement. 
            </p>
            <p>
            <strong>9. WARRANTY</strong>
            </p>
            <p>
            Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of Licensee's download. 
            </p>
            <p>
            9.2	Licensor warrants that the Licensed Application works as described in the user documentation.
            </p>
            <p>
            9.3	No warranty is provided that the Licensed Application will be executable on the Licensee's device as it is configured at the time of download.
            </p>
            <p>
            9.4 	Licensee is required to inspect the Licensed Application prior to submitting their registration which is the act of agreeing to the terms and conditions herein.
            </p>
            <p>
            9.5 	If the Licensed Application is defective, Licensor may attempt to remedy the situation by means of solving the defect if feasible.
            </p>
            <p>
            9.6	In the event of any failure of the Licensed Application, Licensee’s recourse may be to delete the application from their device(s).  Licensor has no other 
            warranty obligation whatsoever with respect to the Licensed Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.
            </p>
            <p>
            <strong>10. PRODUCT CLAIMS</strong>
            </p>
            <p>
            Licensor is responsible for addressing any claims of the Licensee or any third party relating to the Licensed Application or the Licensee's possession and/or use of that Licensed Application, including,
             but not limited to product liability claims; any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement; and claims arising under consumer protection, privacy, or similar legislation.
            </p>
            <p><strong>
            11. LEGAL COMPLIANCE
            </strong>
            </p>
            <p>
            Licensee represents and warrants that Licensee is not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country; and that 
            Licensee is not listed on any US Government list of prohibited or restricted parties.
            </p>
            <p><strong>
            12. CONTACT INFORMATION
            </strong>
            </p>
            <p>
            For general inquiries, complaints, questions, or claims concerning the Licensed Application, please use the feedback mechanism in the Licensed Application.
            </p>
            <p><strong>
            13. TERMINATION
            </strong>
            </p>
            <p>
            The license is valid until terminated by the Licensor. Licensee's rights under this license will terminate automatically and without notice if the Licensee fails to adhere to any term(s) of this license. Upon License termination, Licensee shall stop all 
            use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.
            </p>
            <p><strong>
            14. INTELLECTUAL PROPERTY RIGHTS
            </strong>
            </p>
            <p>
            Licensor will be solely responsible for the investigation, defense, settlement, and discharge or any intellectual property infringement claims against the Licensed Application.
            </p>
            <p><strong>
            15. APPLICABLE LAW</strong>
            </p>
            <p>
            This License Agreement is governed by the laws of the State of North Carolina excluding its conflicts of law rules.
            </p>
            <p><strong>
            16. MISCELLANEOUS</strong>
            </p>
            <p>
            16.1 If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.
            </p>
            <p>16.2 Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.</p>



        </div>
      </Dialog>
      
    </div>
  )
}

export default EULApopover