import { FormControlLabel, FormGroup, Popover, Switch } from '@mui/material';
import * as React from 'react';
import type { UserInfo } from '../UserInfo';
import { makeStyles } from '@mui/styles';

const PhoneContactPopup = ({userInfo, setUserInfo, setOpen, open, ref, isMobile}:
    {userInfo: UserInfo; setUserInfo: (info: UserInfo) => void; open: boolean; setOpen: (open: boolean) => void; ref: any; isMobile: boolean}) => {

    const [checked, setChecked] = React.useState(false);
    
    const handleChange = (event: any) => {
        
        setChecked(event.target.checked);
        setUserInfo({...userInfo, preferred_contact : event.target.checked ? "both" : "email"});
    

    };
    const handleClose = () => {
      setOpen(false);
    }
    const useStyles = makeStyles(() => ({
      switch: {
        '& .MuiSwitch-thumb': {
          backgroundColor: 'darkred',
        },
        '& .MuiSwitch-track': {
          backgroundColor: 'lightgray',
        },
      },
    }));

    const classes = useStyles();

  
  


  return (

    
    <div>
    <Popover
      open={open}
      anchorEl={ref}
      onClose={handleClose}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style = {{
        alignContent: 'center',
        width: isMobile ? 150 : 400,
        marginLeft: isMobile ? 250 : 1080,
        top: isMobile ? -100 : -110
      }}
      >
      <div style = {{padding: '10px', fontSize: '10px'}}>
        <h4 >Why do we ask for this?</h4>
        <p>
          We encourage you to allow scientists to contact you if they have follow up questions
          about your post. Please select if you'd like to allow communication via SMS texting.
        </p>
        <FormGroup>
            <FormControlLabel
            control={ 
                <Switch
                className={classes.switch}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />} 
            label="Contact me by Phone"
            />
        </FormGroup>
      

      </div>
      </Popover>
    </div>
    
  )
}

export default PhoneContactPopup