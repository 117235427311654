import * as React from 'react';
import { CircularProgress, Dialog, IconButton, Typography } from "@mui/material"
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

const SuspendAccount = (props: {targetUserid: Number, targetUser: String, targetRole: String, open: boolean, handleClose: () => void}) => {

    let targetUserid = props.targetUserid
    let targetUser = props.targetUser
    let targetRole = props.targetRole

    const [pageState, setPageState] = React.useState('open');

    let handleSubmit = () => {
        setPageState('submitting')
        targetRole = 'suspended'
        handleSaveNewRole()
    }

    let handleSaveNewRole = async () => {
        fetch(`/api/users/role/`, {

          method: 'PATCH',
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            targetID: targetUserid,
            newRole: targetRole,
          })
        })
        .then((response) => {
            if(response.ok){
                setPageState('success')
                return response
              }
              else{
                setPageState('error')
                throw response
              }
        })
    }
    
    return (

        <Dialog open={props.open} onClose={props.handleClose}>

            <DialogTitle>
                <Typography variant="h4" fontSize={25}>Suspend Account</Typography>
                <IconButton
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    { pageState === 'open' &&
                        <div>
                            <Typography gutterBottom>Suspend this account so they can no longer log into Did You See It?</Typography>
                            <Typography>Username: {targetUser}</Typography>
                            <Typography>Role: {targetRole}</Typography>
                        </div>
                    }

                    { pageState === 'submitting' &&
                        <div>
                            <CircularProgress />
                            <Typography>
                                Sending request.
                            </Typography>
                        </div>
                    }

                    { pageState === 'success' &&
                        <Typography>
                            User has been successfully suspended.
                        </Typography>
                    }

                    { pageState === 'error' &&
                        <Typography>
                            Something went wrong, please try again later.
                        </Typography>
                    }
                </DialogContentText>

                <DialogActions>
                    { pageState !== 'success' && <Button onClick={props.handleClose}> No </Button> }
                    { pageState === 'success' && <Button onClick={props.handleClose}> Ok </Button> }
                    { pageState === 'open' && <Button onClick={handleSubmit}> Yes </Button> }
                    { pageState === 'submitting' && <Button disabled> Yes </Button> }
                </DialogActions>
            </DialogContent>
        </Dialog>
        )
}

export default SuspendAccount