import * as React from 'react';
import { Box } from '@mui/material'
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'

import DeleteAccount from './DeleteAccount';
import { Restricted } from "../utils/permissions";
import VolcanologistContactDialog from './VolcanologistContactDialog';
import SuspendAccount from './SuspendAccount';
import ChangeUserRoleDialog from './ChangeUserRoleDialog';
import EditAccountDialog from './EditAccountDialog';
import { Link } from './Link';
import RoleRequest from './RoleRequest';



export function UserMenu({
    username,
    userid,
    postid,
    email,
    countrycode,
    phoneNumber,
    contactMethod,
    useMetric,
    } : {
    username:string;
    userid:string;
    postid?:string;
    email?:string;
    countrycode?:string;
    phoneNumber?:string;
    contactMethod?:string;
    useMetric?:boolean;
    }) {      
        let location = useLocation();
        let navigate = useNavigate();

        const page = location.pathname.split('/')
        useEffect(() => {
            console.log(location.pathname);
          }, [location]);
        

        const [anchor, setAnchor] = useState<null | HTMLElement>(null)

        const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchor(event.currentTarget);
        };

        const closeMenu = () => {
            setAnchor(null);
        };

        /*
        *  Edit Account Modal
        */
        const [openEditAccount, setOpenEditAccount] = React.useState(false);
        const handleOpenEditAccount = () => {
            getUserInfo();
            setOpenEditAccount(true);
        }
        const handleCloseEditAccount = () => setOpenEditAccount(false);

        /*
        *  Suspend Account Modal
        */
        const [openSuspend, setOpenSuspend] = React.useState(false);
        const handleOpenSuspend = () => {
            getCurrentRole();
            setOpenSuspend(true);
        }
        const handleCloseSuspend = () => setOpenSuspend(false);

        /*
        * Change Role Modal
        */
        const [openRole, setOpenRole] = React.useState(false);
        const handleOpenRole = () => {
            getCurrentRole();
            setOpenRole(true);
        }
        const handleCloseRole = () => setOpenRole(false);

        const [currentRole, setCurrentRole] = useState('');

        let getUserInfo = async () => {
            fetch(`/api/users/${userid}/`, {
                method: 'GET',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                }
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                username = data.username;
                email = data.email
            })
            .catch((error) => {
                console.error("Error:", error)
            })
        }


        let getCurrentRole = async () => {
            fetch(`/api/users/role/`, {
                method: 'POST',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    targetID: userid,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                setCurrentRole(data.targetRole)
            })
            .catch((error) => {
                console.error("Error:", error)
            })
        }

        /*
        * Send Message from Volcanologist Modal
        */

        const [volcOpen, setVolcOpen] = React.useState(false);
        const handleVolcOpen = () => setVolcOpen(true);
        const handleVolcClose = () => setVolcOpen(false);

        const fromContact = localStorage.getItem('userID') ?? undefined;

        switch (page[1]) {
            case 'myprofile': {
                return(
                    <Box style = {{alignItems: 'center'}}>
                        <Button onClick={openMenu}
                            variant="outlined"
                            sx={{ m: 3, width: 250}}
                        >
                            More Actions
                        </Button>
                        <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={closeMenu}
                            keepMounted
                            PaperProps={{
                            style: {
                                maxHeight: 40 * 6,
                                width: 250
                            },
                            }}
                        >
                            <MenuItem onClick={handleOpenEditAccount}>
                                Edit account
                            </MenuItem>
                            <EditAccountDialog
                                username={username}
                                userid={userid}
                                email={email}
                                countrycode={countrycode}
                                phoneNumber={phoneNumber}
                                contactMethod={contactMethod}
                                useMetric={useMetric}
                                open={openEditAccount}
                                handleClose={handleCloseEditAccount}
                            />

                            <MenuItem>
                                <Link color="inherit" underline="none" to="/password-change">Change password</Link>
                            </MenuItem>

                            <MenuItem>
                                <Link color="inherit" underline="none" to="/logout">Log out</Link>
                            </MenuItem>

                            <DeleteAccount userid = {userid}/>
                            
                            <RoleRequest/>

                        </Menu>
                    </Box>
                )
            }

            default: {
                return(
                    <Box>
                        <Button onClick={openMenu} 
                            variant="outlined"
                            sx={{ m: 3, width:250 }}
                        >
                            More Actions
                        </Button>
                        <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={closeMenu}
                            keepMounted
                            PaperProps={{
                            style: {
                                maxHeight: 40 * 6,
                                width: 250
                            },
                            }}
                        >
                            <Tooltip title='Report this user to our moderation team.' placement='right' enterDelay={250}>
                                <MenuItem onClick={() => { navigate(`/reportUser/${userid}`)}}>
                                    Report user
                                </MenuItem>
                            </Tooltip>
                            

                            {fromContact !== undefined && postid !== undefined ?
                                <Restricted role="volcanologist">
                                    <>
                                        <Tooltip title='Send default message to this users preferred contact.' placement='right' enterDelay={250}>
                                            <MenuItem onClick={handleVolcOpen}>
                                                Contact user
                                            </MenuItem>
                                        </Tooltip>
                                        <VolcanologistContactDialog userid={userid} fromContact={fromContact} postid={postid} open={volcOpen} handleClose={handleVolcClose}/>
                                    </>
                                </Restricted>
                            : undefined }
                            <Restricted role="moderator">
                            <Tooltip title='(Moderator Only) Suspend this user.' placement='right' enterDelay={250}>
                                <MenuItem onClick={handleOpenSuspend}>
                                    Suspend account
                                </MenuItem>
                            </Tooltip>
                            </Restricted>
                            <SuspendAccount targetUserid={Number(userid)} targetUser={username} targetRole={currentRole} open={openSuspend} handleClose={handleCloseSuspend}/>

                            <Restricted role="admin">
                            <Tooltip title="Change this user's role" placement='right' enterDelay={250}>
                                <MenuItem onClick={handleOpenRole}>
                                    Change role
                                </MenuItem>
                            </Tooltip>
                            </Restricted>
                            <ChangeUserRoleDialog targetUserid={userid} targetUser={username} targetRole={currentRole} open={openRole} handleClose={handleCloseRole}/>
                            
                        </Menu>
                    </Box>
                )
            }
        }
        
   

}