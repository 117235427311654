import { DBSchema, openDB } from "idb";
import { useEffect, useState } from "react";
import type { Location } from "./Post";
import { useGeolocation } from "./utils/useGeolocation";

export type Volcano = {
  id: number;
  name: string;
  primaryVolcanoType: string | undefined;
  lastEruptionYear: string | undefined;
  country: string;
  location: Location;
  elevation: string | undefined;
  tectonicSetting: string | undefined;
  geologicEpoch: string;
  majorRockType: string | undefined;
  region: string | undefined;
};

export type RawVolcano = {
  volcano_number: string;
  volcano_name: string;
  primary_volcano_type: string | null;
  last_eruption_year: string | null;
  country: string;
  latitude: string;
  longitude: string;
  elevation: string | null;
  tectonic_setting: string | null;
  geologic_epoch: string;
  major_rock_type: string | null;
  region: string | null;
};

export function fromRawVolcano(untyped: unknown): Volcano {
  const raw = untyped as RawVolcano;
  return {
    id: Number(raw.volcano_number),
    name: raw.volcano_name,
    primaryVolcanoType: raw.primary_volcano_type ?? undefined,
    lastEruptionYear: raw.last_eruption_year ?? undefined,
    country: raw.country,
    location: { lat: Number(raw.latitude), long: Number(raw.longitude) },
    elevation: raw.elevation ?? undefined,
    tectonicSetting: raw.tectonic_setting ?? undefined,
    geologicEpoch: raw.geologic_epoch,
    majorRockType: raw.major_rock_type ?? undefined,
    region: raw.region ?? undefined,
  };
}

export function makeRawVolcano(volcano: Volcano): RawVolcano {
  const raw: RawVolcano = {
    volcano_number: String(volcano.id),
    country: volcano.country,
    elevation: volcano.elevation ?? null,
    geologic_epoch: volcano.geologicEpoch,
    last_eruption_year: volcano.lastEruptionYear ?? null,
    latitude: String(volcano.location.lat),
    longitude: String(volcano.location.long),
    volcano_name: volcano.name,
    region: volcano.region ?? null,
    major_rock_type: volcano.majorRockType ?? null,
    primary_volcano_type: volcano.primaryVolcanoType ?? null,
    tectonic_setting: volcano.tectonicSetting ?? null,
  };

  return raw;
}

type VolcanoAlias = {
  name: string;
  id: number;
};

export interface VolcanoDb extends DBSchema {
  volcanoes: {
    key: number;
    value: Volcano;
  };
  "volcano-aliases": {
    key: string;
    value: VolcanoAlias;
  };
}

const listeners: (() => void)[] = [];

let done = false;

function waitForVolcanoDb(): Promise<void> {
  if (done) {
    return Promise.resolve(undefined);
  } else {
    return new Promise((res) => listeners.push(res));
  }
}

function volcanoDbDone() {
  done = true;
  for (const listener of listeners.splice(0, listeners.length)) {
    listener();
  }
}

function openVolcanoDbWithoutWaiting() {
  return openDB<VolcanoDb>("did-you-see-it", 1, {
    upgrade(db) {
      db.createObjectStore("volcanoes", {
        keyPath: "id",
      });
      db.createObjectStore("volcano-aliases");
    },
  });
}

export async function openVolcanoDb() {
  await waitForVolcanoDb();
  return openVolcanoDbWithoutWaiting();
}

export async function updateVolcanoDb() {
  const lastUpdated = localStorage.getItem("volcanoes-last-updated");
  if (
    lastUpdated !== null &&
    Date.now() - Number(lastUpdated) < 1000 * 60 * 60 * 24 * 7
  ) {
    volcanoDbDone();
    return;
  }

  const db = await openVolcanoDbWithoutWaiting();

  const start = Date.now();

  let data: Array<RawVolcano>;
  let rawAliases: Array<{ volcano_number: string; volcano_name: string }>;

  try {
    data = await fetch("/api/gvp/").then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    });

    rawAliases = await fetch("/api/gvp/aliases").then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    });
  } catch (e: unknown) {
    console.error(e);
    volcanoDbDone();
    return;
  }

  const tx = db.transaction(["volcanoes", "volcano-aliases"], "readwrite");

  await Promise.all(
    data.map((raw) => {
      const volcano = fromRawVolcano(raw);
      return tx
        .objectStore("volcanoes")
        .put(volcano)
        .catch((err) => {
          console.error("Error writing to idb", err);
          console.error("Data failed: ", volcano);
        });
    })
  );

  await Promise.all(
    rawAliases.map(({ volcano_number, volcano_name }) => {
      return tx
        .objectStore("volcano-aliases")
        .put(
          { id: Number(volcano_number), name: volcano_name },
          volcano_name.toLowerCase()
        )
        .catch((err) => {
          console.error("Error writing to idb", err);
          console.error(
            `Data failed: alias ${volcano_name} => ${volcano_number}`
          );
        });
    })
  );

  await tx.done;

  localStorage.setItem("volcanoes-last-updated", String(start));

  volcanoDbDone();
}

export type VolcanoResult = Volcano & { alias?: string };

export async function getVolcanoes(
  prompt: string,
  count: number
): Promise<VolcanoResult[]> {
  const db = await openVolcanoDb();
  const tx = db.transaction(["volcanoes", "volcano-aliases"]);
  prompt = prompt.toLowerCase();
  let cursor = await tx
    .objectStore("volcano-aliases")
    .openCursor(IDBKeyRange.bound(prompt, `${prompt}\uFFFF`));
  const volcanoes = tx.objectStore("volcanoes");

  const result = [];

  for (let i = 0; cursor && i < count; i += 1) {
    const volcano = (await volcanoes.get(cursor.value.id))!;
    const alias =
      cursor.value.name === volcano.name ? undefined : cursor.value.name;
    result.push({ alias, ...volcano });
    cursor = await cursor.continue();
  }

  return result;
}

export async function getVolcano(id: number) {
  const db = await openVolcanoDb();
  return db.get("volcanoes", id);
}

export function useVolcano(id: number) {
  const [volcano, setVolcano] = useState<undefined | Volcano>(undefined);

  useEffect(() => {
    openVolcanoDb()
      .then((db) => db.get("volcanoes", id))
      .then((volcanoes) => setVolcano(volcanoes));
  }, []);

  return volcano;
}

type NearbyVolcanoQuery = { distance: number; count?: number };

// TODO: use geohashing
export async function getNearbyVolcanoes(
  location: Location,
  query: NearbyVolcanoQuery
): Promise<Volcano[]> {
  const db = await openVolcanoDb();
  const volcanoes = await db.getAll("volcanoes");

  volcanoes.sort(
    (a, b) => distance(a.location, location) - distance(b.location, location)
  );

  const prefixLen = volcanoes.findIndex(
    (volcano, i) =>
      (query.count !== undefined && i >= query.count) ||
      distance(volcano.location, location) > query.distance
  );

  return volcanoes.slice(0, prefixLen);
}

export type NearbyVolcanoesResult =
  | { step: "waiting" }
  | { step: "done"; volcanoes: Volcano[]; location: Location }
  | { step: "error"; error: unknown };

export function useNearbyVolcanoes(query: NearbyVolcanoQuery) {
  const location = useGeolocation();
  const [volcanoes, setVolcanoes] = useState<NearbyVolcanoesResult>({
    step: "waiting",
  });

  useEffect(() => {
    switch (location.step) {
      case "waiting":
        return;
      case "done":
        getNearbyVolcanoes(location.result, query)
          .then((volcanoes) =>
            setVolcanoes({ step: "done", volcanoes, location: location.result })
          )
          .catch((error) => setVolcanoes({ step: "error", error }));
        return;
      case "error":
        setVolcanoes({ step: "error", error: location.error });
    }
  }, [location]);

  return volcanoes;
}

export function useAllVolcanoes() {
  const [volcanoes, setVolcanoes] = useState<undefined | Volcano[]>(undefined);

  useEffect(() => {
    openVolcanoDb()
      .then((db) => db.getAll("volcanoes"))
      .then((volcanoes) => setVolcanoes(volcanoes));
  }, []);

  return volcanoes;
}

function degrees(deg: number) {
  return deg * (Math.PI / 180);
}
export function distance(l1: Location, l2: Location): number {
  const radius = 6370; // km
  const dLat = degrees(l2.lat - l1.lat);
  const dLong = degrees(l2.long - l1.long);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degrees(l1.lat)) *
      Math.cos(degrees(l2.lat)) *
      Math.sin(dLong / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return radius * c;
}

export function useVolcanoesWithPosts() {
  const [ids, setIds] = useState([]);
  useEffect(() => {
    fetch(`api/gvp/volcanoesWithPosts`, {
    }).then((response) => response.json())
    .then(async (json) => setIds(json));
  }, []);

  return ids;
}

