import { useFetch } from "./useFetch";
import { fromFullRawPost, Post } from "../Post";
import { useLocation } from "react-router-dom";

export function usePost(id: number) {
  const { state } = useLocation();

  return useFetch<Post>(`/api/posts/${id}/full`, {
    map: fromFullRawPost,
    cached:
      state && state["viewingPost"]
        ? fromFullRawPost(state["viewingPost"])
        : undefined,
  });
}