import { Box, Typography } from "@mui/material";
import * as React from "react";


export function SafetyText() {
  return (
    <Box
        sx = {{ 
            m: 2,
            p: 2,

        }}
    >
        <Typography variant="h5" gutterBottom id={"safetyinformation"}>
            Safety Information
        </Typography>
        <Typography>1. Keep your eyes on the volcano.</Typography>
        <Typography>2. Protect yourself from ash, wear safety glasses and a mask.</Typography>
        <Typography>3. Avoid low standing areas to escape invisible poisonous gases.</Typography>
        <Typography>4. If you feel the lava heat, step back.</Typography>
        <Typography>5. Follow the direction of authorities and posted signs.</Typography>

    </Box>  
  );
}