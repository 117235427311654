import { HelpOutline } from '@mui/icons-material';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react'
import type { UserInfo } from '../UserInfo';
import PhoneContactPopup from './PhoneContactPopup';
import { countries } from '../countries';

const PhoneEntry = ({isMobile, userInfo, setUserInfo}: 
    {userInfo: UserInfo; setUserInfo: (info: UserInfo) => void;
    isMobile: boolean}) => {

        const handleClick = () => setOpen(prevOpen => !prevOpen);
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);

  return (
    <div style = {{display: 'flex', flexDirection: 'row'}}>
        <Autocomplete
          style={{
            margin: '8px',
            width: isMobile ? 100: 200,
          }}
          id="country-select-demo"
          options={countries}
          getOptionLabel={(option) => `${option.label}`}
          onChange = {(event: any, value: any) => {
            console.log(event, value);
            setUserInfo({...userInfo, country_code: value.phone})

          }}
          renderOption={(props, option) => (
            <Box component="li" sx={{ width: isMobile ? 100 : 200, '& > img': { mr: 2, flexShrink: 0 } , padding: isMobile ? 10 : 20, fontSize: isMobile ? 10 : 20}} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country Code"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          />
          <TextField
          style={{
            margin: '8px',
            width: isMobile ? 219 : 368
          }}
          label="Phone"
          onChange={(e) => {
            setUserInfo({...userInfo, phone: e.target.value});
          }}
          InputProps={{
            endAdornment: (
              <IconButton
              ref={anchorRef} 
              onClick={handleClick}
             >
              <HelpOutline
                style={{fontSize: 20, color: 'darkred'}}>Question
              </HelpOutline>
              </IconButton>
            )
          }}
          >
          </TextField>

          <PhoneContactPopup userInfo={userInfo} setUserInfo={setUserInfo} setOpen = {setOpen} open = {open} ref={anchorRef} isMobile = {isMobile}/>
      
    </div>
  )
}

export default PhoneEntry;
