import * as React from "react";
import { useState, useEffect } from "react";
import type { UserInfo } from "../UserInfo";
import { Link } from "./Link";
import { SearchList } from "./SearchList";

export default function UserSearch() {
  const [input, setInput] = useState("");
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (input === "") {
      setUsers([]);
      setFetching(false);
    } else {
      setFetching(true);
      const getData = setTimeout(() => {
        fetch(`api/users/users/${input}/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setUsers(data);
            setFetching(false);
          });
      }, 200);
      return () => clearTimeout(getData);
    }
    return () => {};
  }, [input]);

  const items = users.map((user) => {
    if(user.role !== "suspended"){
      return (
        <Link
          to={`/profile/${user.user_id}`}
          key={user.user_id}
          >
            {user.username}
        </Link>
      );
    }
    else return (<></>);
  }
  );

  let emptyMessage;

  if (fetching) {
    emptyMessage = "";
  } else if (input === "") {
    emptyMessage = "Enter a username to search.";
  } else {
    emptyMessage = "No users found.";
  }

  return (
    <SearchList
      label="Search for a user"
      setQuery={setInput}
      items={items}
      emptyPlaceholder={emptyMessage}
    />
  );
}