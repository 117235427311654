import * as React from "react";
import { styled } from "@mui/system";

const SizedImageInner = styled("img")({
  maxHeight: "80vh",
  maxWidth: "100%",
});

const ImageContainer = styled("div")({
  width: "min(800px, 100vw)",
  height: "max-content",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const FixedImageContainer = styled(ImageContainer)({
  height: "min(600px, 80vh, 100vh - 200px)",
});

export function SizedLinkImage(
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) {
  return (
    <ImageContainer>
      <a href={props.src} target="_blank" rel="noreferrer">
        <SizedImageInner {...props} />
      </a>
    </ImageContainer>
  );
}

export function SizedFixedImage(
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) {
  return (
    <FixedImageContainer>
      <SizedImageInner {...props} />
    </FixedImageContainer>
  );
}