import { Button } from '@mui/material'
import * as React from 'react'
import {useState} from 'react'

const SubmitPassReset = () => {

    const [email, setEmail] = useState("")
    const [statusMessage, setStatusMessage] = useState("")

    let submitReset = async () => {
        console.log('email', email)
        fetch(`/auth/users/reset_password/`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({email})
        }).then((response) => {
            // The server should respond with 204 for non-existent emails.
            // See https://cheatsheetseries.owasp.org/cheatsheets/Forgot_Password_Cheat_Sheet.html#forgot-password-request
            if(response.status === 204){
                setStatusMessage("Please check your email to complete password reset.")
                return response.json()
            } else {
                setStatusMessage("An error occurred. Please try again.")
                throw response
            }

        })
    }




  return (
    <div>
        <h2>Send Password Reset Email:</h2>
        {statusMessage}
        <h3>Enter your email:</h3>
        <input
        type="text"
        placeholder="Email"
        onChange = {(e) => {
            setEmail(e.target.value)
        }}></input>

        <Button
        onClick = {() => submitReset()}
        >Send Reset Email</Button>

    </div>
  )
}

export default SubmitPassReset;