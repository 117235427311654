import * as React from 'react';
import { CircularProgress, Dialog, IconButton, Typography } from "@mui/material"
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

const VolcanologistContactDialog = (props: {userid: string, fromContact: string, postid: string, open: boolean, handleClose: () => void}) => {

    let userid = props.userid
    let fromContact = props.fromContact
    let postid = props.postid

    const [pageState, setPageState] = React.useState('open');

    let handleSubmit = () => {
        setPageState('submitting')

        sendVolcanologistInquiry()
    }

    let sendVolcanologistInquiry = async () => {
        fetch(`/api/users/volcanologistContact/`, {

          method: 'POST',
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            toContact: userid,
            fromContact: fromContact,
            postid: postid,
          })
        })
        .then((response) => {
          if(response.ok){
            setPageState('success')
            return response
          }
          else{
            setPageState('error')
            throw response
          }
        })
      }

    
    return (

        <Dialog open={props.open} onClose={props.handleClose}>

            <DialogTitle>
                <Typography variant="h4" fontSize={25}>Contact User</Typography>
                <IconButton
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    { pageState === 'open' &&
                        <Typography>
                            Clicking yes will send an email/text message to the user. It will include your contact information for them to follow up with you. Are you sure you want to send this message?
                        </Typography>
                    }

                    { pageState === 'submitting' &&
                        <div>
                            <CircularProgress />
                            <Typography>
                                Please wait while your message is being sent.
                            </Typography>
                        </div>
                    }

                    { pageState === 'success' &&
                        <Typography>
                            Your message has been successfully sent.
                        </Typography>
                    }

                    { pageState === 'error' &&
                        <Typography>
                            Something went wrong, please try again later.
                        </Typography>
                    }
                </DialogContentText>

                <DialogActions>
                    { pageState !== 'success' && <Button onClick={props.handleClose}> No </Button> }
                    { pageState === 'success' && <Button onClick={props.handleClose}> Ok </Button> }
                    { pageState === 'open' && <Button onClick={handleSubmit}> Yes </Button> }
                    { pageState === 'submitting' && <Button disabled> Yes </Button> }
                </DialogActions>
            </DialogContent>
        </Dialog>
        )
}

export default VolcanologistContactDialog