import * as React from "react";
import { useNavigate } from "react-router-dom";
import { VolcanoNameSearch } from "../components/VolcanoNameSearch";

export function VolcanoSearch() {
  const navigate = useNavigate();

  return (
    <main>
      <VolcanoNameSearch
        onSubmit={(volcano) => navigate(`/volcano/${volcano}`)}
      />
    </main>
  );
}