import { Button } from '@mui/material';
import * as React from 'react';

const Logout = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (localStorage.getItem('token') == null) {
      window.location.replace('/login/');
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogout = (e:any) => {
    e.preventDefault();
    localStorage.clear(); //Clear everything on local storage. Logout on frontend
    window.location.replace('/login/');
  };

  return (
    <div>
      {loading === false && (
        <React.Fragment>
          <h1>Do you want to logout?</h1>
          <Button value='Logout' onClick={handleLogout}>Logout</Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default Logout;